import { select, call, put } from 'redux-saga/effects';
import logger from '@nike/ciclp-utils/logger';
import {
  languageCodeSelector,
  marketplaceSelector,
} from '../store/state/stateSelector';
import { urlIngredientsSelector } from '../store/data/urlIngredientsSelector';
import { urlGeneratorApiActions } from '../actions';
import { clientIdSelector } from '../store/config/configSelector';
import { fetchUrls } from '../services/fetchUrls';

// goes over all destinations in nodes and requests urls by ingredient
export const fetchUrlsSaga = function* () {
  const language = yield select(languageCodeSelector);
  const marketplace = yield select(marketplaceSelector);
  const clientId = yield select(clientIdSelector);
  const ingredients = yield select(urlIngredientsSelector);
  const onFetchError = error => {
    const { name, message, stack, details } = error;
    logger.warn({
      name,
      message,
      stack,
      details,
    });
  };
  const urls = yield call(fetchUrls, {
    fetchFn: globalThis.extendedFetch,
    ingredients,
    language,
    marketplace,
    clientId,
    onFetchError,
  });
  yield put(urlGeneratorApiActions.success(urls));
};
