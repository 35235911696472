import { combineReducers } from 'redux';
import isBootstrapped from './isBootstrapped';
import featureFlags from './featureFlags';
import error from './error';
import privacy from './privacy';
import analysis from './analysis';
import canonicalUrl from './canonicalUrl';
import analytics from './analytics';
import analyticsUserId from './analyticsUserId';
import shopTheLookReducer from './shopTheLook';
import identityReducer from './identity';
import optimization from './optimization';
import optimizely from './optimizely';
import translationsReducer from './translations';
import requestMeta from './requestMeta';
import previewToken from './previewToken';
import location from './location';
import createReducer from '../../utils/createReducer';

const emptyReducer = createReducer(null, {});

export const stateReducer = combineReducers({
  isBootstrapped,
  error,
  privacy,
  analysis,
  canonicalUrl,
  analytics,
  analyticsUserId,
  ...shopTheLookReducer,
  ...translationsReducer,
  ...identityReducer,
  ...optimization,
  ...optimizely,
  requestMeta,
  location,
  featureFlags,
  previewToken,
  session: emptyReducer,
});
