import { getMarketingPayload } from './payloadStubs/payload';
import { eventNames } from './constants';
import { analyticsActions } from '../actions';

export const mcMiddleware = store => next => action => {
  if (action.type === analyticsActions.page.type) {
    const state = store.getState();

    const eventPayload = getMarketingPayload({
      eventName: eventNames.PAGE_VIEWED,
      state,
      eventData: action.payload,
    });

    (window.NikeConsumerSignals || (window.NikeConsumerSignals = [])).push(
      eventPayload,
    );
  }

  next(action);
};
