import { APIError } from './apiError';
import { ExtendedOptions, ErrorMessage } from './types';

export type FetchWrapper = (
  fetchFn: typeof fetch,
  resource?: string,
) => typeof fetch;

export interface ExtendedFetchOptions {
  fetchFn: typeof fetch;
  url: RequestInfo;
  options?: ExtendedOptions;
  resource?: string;
}

export type ExtendedFetch = (
  options: ExtendedFetchOptions,
) => Promise<Response>;

const getTraceId = (response: Response): string =>
  (response.headers.get('X-B3-TraceId') ||
    response.headers.get('x-b3-traceId')) ??
  '';

export const extendableFetch =
  (wrappers: FetchWrapper[]): ExtendedFetch =>
  ({ fetchFn, url, options, resource }: ExtendedFetchOptions) => {
    return wrappers.reduce(
      (resultFetchFn, wrapperFn) => wrapperFn(resultFetchFn, resource),
      fetchFn,
    )(url, options);
  };

export const timeoutHandler: FetchWrapper =
  (fetchFn, resource) =>
  (
    url: string,
    { timeout = 1000, ...options }: ExtendedOptions = {
      path: url,
    },
  ) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(
          new APIError(ErrorMessage.TIMEOUT, {
            url,
            requestOptions: options,
            requestBody: options?.body && JSON.parse(options.body as string),
            resource,
            timeout,
          }),
        );
      }, timeout);
      try {
        fetchFn(url, options)
          .then(resolve)
          .catch(reject)
          .finally(() => clearTimeout(timeoutId));
      } catch (e) {
        // if we have a APIError object already thrown from the try block pass that forward
        if (e instanceof APIError) {
          reject(e);
        }
        // all other generic errors
        reject(
          new APIError(ErrorMessage.GENERIC, {
            url,
            requestOptions: options,
            requestBody: options?.body && JSON.parse(options.body as string),
            resource,
            timeout,
            originalMessage: e.message,
          }),
        );
      }
    });
  };

export const errorHandler: FetchWrapper =
  (fetchFn, resource) =>
  async (
    url: string,
    options: ExtendedOptions = {
      path: url,
    },
  ) => {
    try {
      const response = await fetchFn(url, options);

      if (!response?.ok) {
        let body = null;
        try {
          body = await response.text();
        } catch (err) {
          // nothing to do here
        }
        throw new APIError(ErrorMessage.SERVICE_RESPONSE_FAILED, {
          url,
          traceId: response && getTraceId(response),
          requestOptions: options,
          requestBody: options?.body && JSON.parse(options.body as string),
          responseStatus: response.status,
          responseStatusText: response.statusText,
          responseBody: body,
          resource,
        });
      }
      return response;
    } catch (e) {
      // if we have a APIError object already thrown from the try block pass that forward
      if (e instanceof APIError) {
        throw e;
      }
      // all other generic errors
      throw new APIError(ErrorMessage.GENERIC, {
        url,
        requestOptions: options,
        requestBody: options?.body && JSON.parse(options.body as string),
        resource,
        originalMessage: e.message,
      });
    }
  };

export const customFetch = extendableFetch([
  timeoutHandler,
  errorHandler,
  // callerIdHandler
]);
