import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import { stateReducer } from './state/stateReducer';
import { dataReducer } from './data/dataReducer';
import { routerReducer } from './router/routerReducer';
import storageReducer from './storage';
import { persistedMeasurementReducer } from './measurement';
import { storePatchesReducer } from './storePatches';

const appReducer = combineReducers({
  appState: stateReducer,
  appData: dataReducer,
  router: routerReducer,
  storePatches: storePatchesReducer,
  ...storageReducer(),
  ...persistedMeasurementReducer(),
});

export const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    state = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
  }
  return appReducer(state, action);
};
