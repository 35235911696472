import { call, put, select } from 'redux-saga/effects';
import { CHANNEL_IDS } from '@nike/ciclp-config';
import { getThreads } from '@nike/ciclp-data-fetch-layer';

import {
  languageCodeSelector,
  marketplaceSelector,
} from '../store/state/stateSelector';
import { clientIdSelector } from '../store/config/configSelector';
import { productApiAction } from '../actions';

export const productFields = [
  'id',
  'publishedContent.properties.productCard.properties.squarishURL',
  'publishedContent.properties.productCard.properties.squarishId',
  'publishedContent.properties.seo.slug',
  'publishedContent.properties.productCard.properties.altText',
  'publishedContent.properties.title',
  'publishedContent.properties.subtitle',
  'productInfo.merchPrice.fullPrice',
  'productInfo.merchPrice.currentPrice',
  'productInfo.merchPrice.currency',
  'productInfo.merchPrice.discounted',
  'productInfo.merchProduct.status',
  'productInfo.merchProduct.pid',
  'productInfo.merchProduct.styleColor',
  'productInfo.merchProduct.styleType',
  'productInfo.merchProduct.productGroupId',
  'productInfo.merchProduct.channels',
  'productInfo.customizedPreBuild.groups.legacy.slug',
  'productInfo.customizedPreBuild.groups.legacy.piid',
  'productInfo.customizedPreBuild.legacy.pbid',
  'collectionsv2',
].join(',');

const filterProducts = productList =>
  productList.filter(
    product => product?.productInfo?.[0]?.merchProduct?.status === 'ACTIVE',
  );

export const fetchProductsDetailsSaga = function* (styleColors, params) {
  try {
    const language = yield select(languageCodeSelector);
    const marketplace = yield select(marketplaceSelector);
    const callerId = yield select(clientIdSelector);

    const productsDetails = yield call(getThreads, {
      fetchFn: globalThis.extendedFetch,
      params: {
        styleColors,
        channelId: CHANNEL_IDS.DEFAULT,
        language,
        marketplace,
        callerId,
        fields: productFields,
        sort: 'productInfo.merchProduct.styleColorGiven',
        layout: true,
        'publishedContent.subType': ['soldier', 'nikeid_soldier', 'officer'],
        ...params,
      },
    });
    if (productsDetails) {
      return filterProducts(productsDetails.objects);
    }
    return [];
  } catch (ex) {
    const {
      name = 500,
      message = 'Error fetching product details',
      stack = {},
      details,
    } = ex;
    yield put(
      productApiAction.error({
        name,
        message,
        stack,
        details,
      }),
    );
    throw ex;
  }
};
