import { CARD_TYPES } from '../../../constants';

const userNameTemplate = '[user-full-name]';

export const resolvePersonalizedTitle = ({
  title,
  personalizedTitle,
  userFirstName,
}) =>
  userFirstName
    ? personalizedTitle?.replace(userNameTemplate, userFirstName)
    : title;

export const getRecommenderFallback = ({
  cardData,
  translations: { title, personalizedTitle, topTrendingTitle },
}) =>
  cardData.useFallback
    ? topTrendingTitle
    : resolvePersonalizedTitle({
        title,
        personalizedTitle,
        userFirstName: cardData.userFirstName,
      });

const FALLBACK_RESOLVERS = {
  [CARD_TYPES.PRODUCT_RECOMMENDER]: getRecommenderFallback,
};

export const getCarouselTitleWithFallback = ({
  cardData,
  translations = {},
}) => {
  const title = cardData?.sectionHeadline?.title;
  const fallback =
    FALLBACK_RESOLVERS[cardData?.containerType] || getRecommenderFallback;

  return title || fallback({ cardData, translations });
};
