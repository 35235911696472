import { CARD_TYPES } from '../../../constants';
import {
  extractIngredientsFromList,
  getProductCarouselTranslations,
  getSectionHeadlineTranslation,
  getTitle,
} from '../helpers';
import {
  getActions,
  getProductContainerPlacements,
} from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';

export const prepareCardData = cardData => {
  const sectionHeadline = getTitle(cardData);
  const maxResults = cardData?.properties?.custom?.maxResults;

  return {
    ...getCommonProps(cardData),
    sectionHeadline,
    maxResults,
    slides: [],
    dataQa: CARD_TYPES.EXTERNAL_COLLECTION,
    collectionId: cardData?.properties.valueMap?.external_collection,
  };
};

export const prepareTranslations = translationsStrings => ({
  ...getProductCarouselTranslations(translationsStrings),
  ...getSectionHeadlineTranslation(translationsStrings),
});

export { mergeCarouselProperties as mergeProperties } from '../helpers/carouselHelper/carouselHelper';

export const getUrlIngredients = card => [
  ...extractIngredientsFromList(card?.sectionHeadline.actions),
  ...extractIngredientsFromList(card?.slides),
];

export { getProductContainerPlacements as getAnalyticsPlacements };

export const getAnalyticsActions = card =>
  getActions(card.sectionHeadline.actions);

export { getBuyingToolsIngredients } from './common';
