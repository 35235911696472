class ExtendableError extends Error {
  constructor(message) {
    super(message);
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class DetailedError extends ExtendableError {
  constructor(message, details) {
    super(message);
    this.name = 'DetailedError';
    this.details = details;
  }
}

export const logLevels = {
  info: 1,
  warn: 2,
  error: 3,
};

export class Logger {
  constructor(initialTransports = []) {
    this.transports = initialTransports;
  }

  log(level, message) {
    this.transports.forEach(t => t(level, message));
  }

  info(message) {
    this.log(logLevels.info, message);
  }

  warn(message) {
    this.log(logLevels.warn, message);
  }

  error(message) {
    this.log(logLevels.error, message);
  }

  addTransport(transport) {
    this.transports = this.transports.concat(transport);
    return this;
  }

  resetTransports() {
    this.transports = [];
  }
}

// This instance of logger will be used only for client part of application
// because it will not be shared with different sessions
export default new Logger();
