import {
  customFetch,
  renameFilterProps,
  transformParamsToString,
  FetchParams,
  ErrorMessage,
  DEFAULT_API_URL,
  APIError,
} from '../../common';

export const getProducts = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: ProductFeed.RequestParams & FetchParams;
}): Promise<ProductFeed.Response> => {
  const {
    anchor,
    count,
    sort,
    fields,
    consumerChannelId,
    fetchParams: { baseUrl = DEFAULT_API_URL, timeout } = {},
    ...filters
  } = params;
  const queryString = transformParamsToString({
    anchor,
    count,
    consumerChannelId,
    sort,
    fields,
    filter: renameFilterProps(filters as ProductFeed.RequestFilters),
  });
  const path = 'product_feed/rollup_threads/v2';
  const url = `${baseUrl}/${path}/${queryString}`;
  const requestOptions = {
    method: 'GET',
    headers: {},
    vipAddress: 'product_feed-rollup_threads-v2',
    path,
    timeout,
  };

  const result = await customFetch({
    fetchFn,
    url,
    options: requestOptions,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      url,
      requestOptions,
      originalMessage: e.message,
    });
  }
};
