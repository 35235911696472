import { PAGE_TYPES } from '@nike/ciclp-config';
import { pathOr } from 'ramda';
import { CARD_TYPES, ROLES_UUIDS } from '../../../constants';
import { getPageType, getGlyph, sanitizeText, getPageThread } from '../helpers';
import {
  getProfilesForRole,
  formatArticleDate,
} from '../helpers/commonHelpers';

const getWriters = getProfilesForRole(ROLES_UUIDS.WRITER);

export const prepareCardData = (titleCard, { threads }) => {
  const pageThread = getPageThread(threads);
  const pageType = getPageType(threads);

  const cardData = {
    containerType: CARD_TYPES.TITLE,
    cardId: titleCard?.id,
    title: titleCard?.properties?.title,
    subTitle: titleCard?.properties?.subTitle,
    body: sanitizeText(
      pathOr('', ['properties', 'coverCard', 'properties', 'body'], pageThread),
    ),
    bottomMargin: pageType === PAGE_TYPES.SIZE_CHART ? 'l' : 'xl',
    authors: getWriters(pageThread),
    ...getGlyph(threads),
  };

  if (pageType === PAGE_TYPES.ARTICLE) {
    cardData.isArticle = true;
    cardData.lastUpdatedStamp = pageThread.createdDateTime;
    cardData.readTime = pageThread.properties?.readTime;
  }

  return cardData;
};

export const prepareTranslations = translations => ({
  lastUpdatedTemplate:
    translations?.last_updated_label ?? 'Last updated: [date]',
  readTimeTemplate: translations?.read_time ?? '[durationInMinutes] min read',
});

export const mergeProperties = ({
  cardData,
  translations,
  languageMappings,
}) => {
  const mergedCard = {
    ...cardData,
    ...translations,
  };

  if (cardData.lastUpdatedStamp) {
    mergedCard.lastUpdated = formatArticleDate(
      new Date(cardData.lastUpdatedStamp),
      languageMappings,
    );
  }

  return mergedCard;
};

export { getUrlIngredients, getBuyingToolsIngredients } from './common';
