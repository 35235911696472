import { MEDIA_TABLET } from '@nike/ciclp-react-components/context/utils';
import { pick, values } from 'ramda';
import {
  segmentTrackPayloadSelector,
  segmentViewPayloadSelector,
} from '../analyticsSelectors';
import {
  analyticsProperties,
  eventNames,
  videoEventsNames,
} from '../constants';

export const getAnalyticsEvent = (eventName, state, eventData) => {
  const isMobile = window.innerWidth < MEDIA_TABLET;

  // Page load analytics
  if (eventName === eventNames.PAGE_VIEWED) {
    return segmentViewPayloadSelector(state, { isMobile });
  }
  if (
    [
      eventNames.CLICK,
      eventNames.NAVIGATION_CLICK,
      eventNames.BANNER_CLICK,
    ].includes(eventName)
  ) {
    const { actionId, placementId, clickContentLabel, videoData } = eventData;
    return segmentTrackPayloadSelector(state, {
      actionId,
      placementId,
      isMobile,
      eventName,
      clickContentLabel,
      videoData,
    });
  }
  if (
    [
      eventNames.NOTIFY_ME_CLICKED,
      eventNames.NOTIFY_ME_DISMISSED,
      eventNames.NOTIFY_ME_SUBMITTED,
    ].includes(eventName)
  ) {
    const { productId, clickActivity } = eventData;
    return { productId, clickActivity };
  }
  if (eventName === eventNames.IMPRESSION) {
    const { videoData } = eventData;
    return {
      ...pick(
        [
          analyticsProperties.LANDMARK_X,
          analyticsProperties.LANDMARK_Y,
          analyticsProperties.TIMESTAMP,
          analyticsProperties.COMPONENT_EXPIREMENT_ID,
        ],
        eventData,
      ),
      ...segmentTrackPayloadSelector(state, {
        placementId: eventData.placementId,
        isMobile,
        eventName,
        videoData,
      }),
    };
  }
  if (values(videoEventsNames).includes(eventName) && eventData) {
    const { placementId, videoData } = eventData;
    return {
      ...segmentTrackPayloadSelector(state, {
        placementId,
        isMobile,
        videoData,
      }),
    };
  }
  if (eventName === eventNames.PRODUCT_ADDED) {
    const { productData, clickActivity } = eventData;

    return {
      ...segmentTrackPayloadSelector(state, {
        eventName,
        productData,
        clickActivity,
      }),
    };
  }
  return {};
};
