import { call, select, put, all } from 'redux-saga/effects';
import {
  take as takeFirst,
  pipe,
  map,
  intersection,
  values,
  flatten,
  uniq,
} from 'ramda';
import { API_BASE_URL } from '@nike/ciclp-config';
import logger from '@nike/ciclp-utils/logger';
import { productRecommenderApiAction, recommendationActions } from '../actions';
import { fetchProductRecommenderList } from '../services/productRecommender';
import { normaliseRecommendationsList } from '../services/productsApiNormalizer';
import { fetchProductsDetailsSaga } from './productsDetailsSaga';
import {
  marketplaceSelector,
  languageCodeSelector,
} from '../store/state/stateSelector';
import { clientIdSelector } from '../store/config/configSelector';
import { hasUserAllowedPersonalization } from './personalization';

const MIN_REQUIRED_PRODUCTS = 3;
const PRS_ENABLED_COUNTRIES = ['US', 'JP'];

const getUsedProducts = (recommendedCards, productsList = []) => {
  const recommenderWithProducts = [];
  const recommendedProducts = pipe(
    map(card => {
      let products;
      if (card.taxonomies) {
        const taxonomyProducts = productsList
          .filter(({ taxonomyId }) => card.taxonomies.includes(taxonomyId))
          .map(t => t.recommendations.map(tr => tr.styleColor));
        products = card.clearance
          ? taxonomyProducts.reduce(intersection, taxonomyProducts?.[0]) ?? []
          : takeFirst(
              card.maxResults,
              taxonomyProducts.reduce(intersection, taxonomyProducts?.[0]) ??
                [],
            );
      } else {
        // taking `amountOfItemsFromTaxonomy` items from each products list
        // if there are not enough lists, start from the first again
        products = [];
        const amountOfItemsFromTaxonomy = 2;
        const maxProductsLength = productsList.reduce(
          (acc, item) => Math.max(item.recommendations?.length ?? 0, acc),
          0,
        );
        const currentPosition = [0, 0];
        while (
          products.length < card.maxResults &&
          currentPosition[1] < maxProductsLength
        ) {
          productsList[currentPosition[0]].recommendations
            .slice(
              currentPosition[1],
              currentPosition[1] + amountOfItemsFromTaxonomy,
            )
            .forEach(item => {
              if (
                !products.includes(item.styleColor) &&
                products.length < card.maxResults
              ) {
                products.push(item.styleColor);
              }
            });
          currentPosition[0]++;
          if (currentPosition[0] === productsList.length) {
            currentPosition[0] = 0;
            currentPosition[1] += amountOfItemsFromTaxonomy;
          }
        }
      }
      const useFallback = products.length < MIN_REQUIRED_PRODUCTS;
      recommenderWithProducts.push({
        cardId: card.id,
        ...(!useFallback && { products }),
        useFallback,
        clearance: card.clearance,
        maxResults: card.maxResults,
      });
      return !useFallback ? products : [];
    }),
    flatten,
    uniq,
  )(recommendedCards);

  return {
    recommenderWithProducts,
    recommendedProducts,
  };
};

const remapIntoCardsWithSlides = cards => slides =>
  cards.reduce((cardsWithSlides, c) => {
    const filteredProductSlides = c.useFallback
      ? []
      : slides.filter(s => {
          if (c.clearance) {
            return c.products?.includes(s.styleColor);
          }
          return c.products?.includes(s.styleColor) && !s.isOnSale;
        });
    cardsWithSlides[c.cardId] =
      filteredProductSlides.length >= MIN_REQUIRED_PRODUCTS
        ? {
            id: c.cardId,
            slides: filteredProductSlides,
          }
        : { id: c.cardId, useFallback: true };

    return cardsWithSlides;
  }, {});

/**
 * It tries to fetch the recommended products if at least 3 products are available
 */
export const fetchProductRecommender = function* (recommenderCardsData) {
  const isMember = yield call(window.webShellClient.identity.getIsLoggedIn);
  const marketplace = yield select(marketplaceSelector);

  if (!isMember || !PRS_ENABLED_COUNTRIES.includes(marketplace)) {
    yield put(recommendationActions.usePRSFallback());
    return;
  }

  const canRunPersonalization = yield select(hasUserAllowedPersonalization);
  if (!canRunPersonalization) {
    yield put(recommendationActions.usePRSFallback());
    return;
  }

  const language = yield select(languageCodeSelector);
  const uniteJWT = yield call(window.webShellClient.identity.getAccessToken);
  const callerId = yield select(clientIdSelector, 'product_recommender');
  const { upmId, firstName } = yield call(
    window.webShellClient.identity.getUserProfile,
  );

  try {
    const prsResponse = yield call(fetchProductRecommenderList, {
      marketplace,
      upmId,
      uniteJWT,
      callerId,
      fetchParams: {
        // adjusting timeout because it is made from client
        timeout: 5000,
        baseUrl: API_BASE_URL,
      },
    });

    const { recommendedProducts, recommenderWithProducts } = getUsedProducts(
      recommenderCardsData,
      prsResponse?.insights,
    );

    const recommendedItemsLength = recommendedProducts?.length ?? 0;

    if (recommendedItemsLength > MIN_REQUIRED_PRODUCTS) {
      const recommendedProductsList = yield call(
        fetchProductsDetailsSaga,
        recommendedProducts,
        {
          fetchParams: {
            baseUrl: API_BASE_URL,
            // adjusting timeout because it is made from client
            timeout: 5000,
          },
        },
      );

      const normalizedProducts = yield call(
        normaliseRecommendationsList,
        recommendedProductsList,
      );
      const cardsWithSlides = remapIntoCardsWithSlides(recommenderWithProducts)(
        normalizedProducts,
      );
      if (values(cardsWithSlides).every(c => !c.useFallback)) {
        yield put(recommendationActions.withoutFallback());
      } else {
        yield put(recommendationActions.usePRSFallbackPartly());
      }
      yield put(
        productRecommenderApiAction.success({
          firstName,
          cards: cardsWithSlides,
        }),
      );
    } else {
      logger.warn({
        message: `Product Recommender Service has returned less items than required`,
        details: {
          itemsCount: recommendedItemsLength,
          marketplace,
          language,
        },
      });
      yield put(recommendationActions.usePRSFallback());
    }
  } catch (ex) {
    const {
      name = 500,
      message = 'Error fetching recommender products',
      stack = {},
      details,
    } = ex;

    logger.warn({
      name,
      message,
      stack,
      details,
    });

    yield all([
      put(recommendationActions.usePRSFallback()),
      put(
        productRecommenderApiAction.error({
          name,
          message,
          stack,
          details,
        }),
      ),
    ]);
  }
};
