import { chain, defaultTo, filter, map, pipe } from 'ramda';
import {
  CARD_TYPES,
  DYNAMIC_CONTENT_SUPPORTED_CARD_TYPES,
} from '../../../constants';
import {
  getSectionHeadlineTranslation,
  mergeLinkWithIngredients,
  mergeSectionHeadlineTitle,
} from '../helpers';
import { prepareGalleryCardData } from '../helpers/carouselHelper';
import { prepareTranslations as videoTranslations } from './video';

export const prepareCardData = (cardData, data) =>
  prepareGalleryCardData(cardData, data, CARD_TYPES.RELATED_FILMSTRIP);

export const prepareTranslations = translationsStrings => ({
  ...videoTranslations(translationsStrings),
  ...getSectionHeadlineTranslation(translationsStrings),
});

export const mergeProperties = ({
  cardData = {},
  urls,
  countryCode,
  translations,
}) => {
  const { slides, sectionHeadline, ...restCardData } = cardData;
  return {
    ...restCardData,
    sectionHeadline: mergeSectionHeadlineTitle({
      cardData: sectionHeadline,
      translations,
      urls,
    }),
    slides: pipe(
      defaultTo([]),
      chain(s => ({
        ...mergeLinkWithIngredients({ action: s, urls }),
        actionButtons: s.actionButtons.map(btn => {
          btn.countryCode = countryCode;
          return mergeLinkWithIngredients({
            action: btn,
            urls,
            property: 'destinationId',
          });
        }),
      })),
      filter(slide => {
        const hasCardLink = !!slide.cardLinkUrl;
        const hasTitle = !!slide.subtitleProps.text;
        const hasBody = !!slide.titleProps.text;
        const isSupportedCardType =
          DYNAMIC_CONTENT_SUPPORTED_CARD_TYPES.includes(slide.containerType);
        return hasCardLink && (hasTitle || hasBody) && isSupportedCardType;
      }),
      map(slide => ({ ...translations, ...slide })),
    )(slides),
  };
};

export { getGalleryUrlIngredients as getUrlIngredients } from '../helpers/carouselHelper/carouselHelper';

export {
  getGalleryPlacements as getAnalyticsPlacements,
  getGalleryActions as getAnalyticsActions,
} from '../helpers/analytics';

export { getBuyingToolsIngredients } from './common';
