import createReducer from '../../../utils/createReducer';
import { optimizationActions } from '../../../actions';

const initialState = { initialized: false, analytics: '' };

const composeAnalyticsParam = locations =>
  locations
    .map(location => location.analytics)
    .filter(Boolean)
    .join(',');

export const reducer = createReducer(initialState, {
  [optimizationActions.loadLocations.type]: (state, { payload = [] }) => {
    const analytics = composeAnalyticsParam(payload);
    return {
      initialized: true,
      analytics,
    };
  },
});
