import {
  assoc,
  includes,
  equals,
  find,
  head,
  isEmpty,
  is,
  defaultTo,
  last,
  filter,
  complement,
  both,
  pick,
  pipe,
  chain,
  toPairs,
  whereEq,
} from 'ramda';
import {
  CARD_ACTION_DESTINATION_TYPES,
  CARD_ACTION_TYPES,
  PDP_URL_TYPES,
} from '../../../constants';
import { URL_INGREDIENTS } from './constants';

const pdpUrlTypeCheckers = {
  [PDP_URL_TYPES.NIKEID]: equals('NIKEID'),
  [PDP_URL_TYPES.NIKEDOTCOM]: includes('Nike.com'),
  [PDP_URL_TYPES.SNKRS]: includes('SNKRS'),
};

export const pickUrlByIngredients = (urls = [], urlIngredients = {}) => {
  const urlEntry = find(
    pipe(head, equals(pick(URL_INGREDIENTS, urlIngredients))),
    urls,
  );
  return urlEntry ? last(urlEntry) : null;
};

export const pickBuyingToolFromList = (
  buyingToolsList,
  buyingToolsIngredients = {},
) => find(whereEq(buyingToolsIngredients), buyingToolsList);

export const getPDPURL = {
  [PDP_URL_TYPES.NIKEID]: ({
    urlIngredients = {},
    languageCountryPath,
    urls = [],
  }) => {
    const { slug = '', piid = '', pbid = '' } = urlIngredients;
    return (
      pickUrlByIngredients(urls, urlIngredients) ||
      `https://${process.env.NEXT_PUBLIC_HOST_NAME}/${
        languageCountryPath ?? ''
      }u/${slug}-${piid}/${pbid}`
    );
  },
  [PDP_URL_TYPES.NIKEDOTCOM]: ({
    urlIngredients = {},
    languageCountryPath,
    urls = [],
  }) => {
    const { styleColor = '', slug = '' } = urlIngredients;

    return (
      pickUrlByIngredients(urls, urlIngredients) ||
      `https://${process.env.NEXT_PUBLIC_HOST_NAME}/${
        languageCountryPath ?? ''
      }t/${slug}/${styleColor}`
    );
  },
  [PDP_URL_TYPES.SNKRS]: ({
    languageCountryPath = '',
    urlIngredients = {},
  }) => {
    const { slug = '' } = urlIngredients;
    return `https://${process.env.NEXT_PUBLIC_HOST_NAME}/${
      languageCountryPath ?? ''
    }launch/t/${slug}`;
  },
};

const PDP_LINK_INGREDIENTS = {
  [PDP_URL_TYPES.NIKEID]: ({ slug = '', piid = '', pbid = '' } = {}) => ({
    slug,
    piid,
    pbid,
  }),
  [PDP_URL_TYPES.NIKEDOTCOM]: ({ styleColor, slug } = {}) => ({
    type: 'PDP',
    styleColor,
    slug,
  }),
  [PDP_URL_TYPES.SNKRS]: ({ slug, styleColor } = {}) => ({
    slug,
    styleColor,
  }),
  [PDP_URL_TYPES.NIKEDOTCOM_LEGACY]: ({
    slug = '',
    pid = '',
    productGroupId = '',
  } = {}) => ({
    slug,
    pid,
    productGroupId,
  }),
};

export const getPDPUrl = ({ pdpType, ...params }) =>
  getPDPURL?.[pdpType](params);

export const getPDPIngredients = ({ pdpType, ...params }) =>
  PDP_LINK_INGREDIENTS?.[pdpType](params);

export const getPDPType = (styleType, channelInfo) =>
  pipe(
    toPairs,
    find(([, checker]) =>
      checker((styleType === 'NIKEID' ? styleType : channelInfo) || ''),
    ),
    pair => (pair ? pair[0] : PDP_URL_TYPES.NIKEDOTCOM),
  )(pdpUrlTypeCheckers);

export const getUrlIngredients = ({ destination = {} } = {}) =>
  pick(URL_INGREDIENTS, destination);

/**
 *    @description extract all URL ingredients from card and returns them as array of urlIngredients
 *    @param {Object[]} arr list of objects with url ingredients
 *    @returns {Object[]} urls array with all url ingredients object
 *    extractIngredientsFromList: arr => urls,
 * */
export const extractIngredientsFromList = pipe(
  defaultTo([]),
  chain(({ urlIngredients, actions }) => [
    urlIngredients,
    ...((actions && extractIngredientsFromList(actions)) ?? []),
  ]),
  filter(both(complement(isEmpty), is(Object))),
);

/**
 *    @description replaces link urlIngredients with url
 *    @param {Object} link object of link
 *    @param {Object} urlIngredients object with ingredients that matches to some URL from the list
 *    @param {[Object, string][]} urls list of generated URLs
 *    @param {Object} property name of property for inserted link
 *    @returns {Object} linkWithUrl prepared data needed on the page
 *    mergeLinkWithIngredients: ({ urlIngredients, ...link }, urls, property) => linkWithUrl,
 * */
export const mergeLinkWithIngredients = ({
  action: {
    urlIngredients = {},
    buyingToolsIngredients = {},
    actions,
    ...link
  } = {},
  urls,
  property = 'cardLinkUrl',
  buyingToolsList = [],
}) => {
  let url = pickUrlByIngredients(urls, urlIngredients) || link[property];

  if (actions) {
    link.actions = actions.map(a =>
      mergeLinkWithIngredients({ action: a, urls, buyingToolsList, property }),
    );
  }

  if (url && link.destinationType === CARD_ACTION_DESTINATION_TYPES.POPUP) {
    url = url
      .replace('[country]', link.countryCode)
      .replace('[language]', link.language);
  }

  if (link.actionType === CARD_ACTION_TYPES.BUYING_TOOLS) {
    return assoc(
      'buyingToolsIngredients',
      pickBuyingToolFromList(buyingToolsList, buyingToolsIngredients),
      link,
    );
  }
  return assoc(property, url, link);
};
