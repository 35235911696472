import { privacyActions } from '../../../actions';
import createReducer from '../../../utils/createReducer';

export default createReducer(
  {
    isSet: false,
    level: 0,
    allowPerformance: false,
    allowMarketing: false,
    isNikePrivacyAllowed: false,
  },
  {
    [privacyActions.set.type]: (state, { payload }) => payload,
    [privacyActions.setNikePrivacy.type]: (state, { payload }) => ({
      ...state,
      isNikePrivacyAllowed: payload.isNikePrivacyAllowed,
    }),
  },
);
