import { assocPath, path } from 'ramda';
import constants from '@nike/ciclp-react-components/constants';
import { prepareTranslations as videoTranslations } from './video';
import { getSectionHeadlineTranslation } from '../helpers';
import { getCommonProps, getTitle } from '../helpers/commonHelpers';
import { mergeGalleryProperties } from '../helpers/carouselHelper';

export const prepareCardData = cardData => ({
  ...getCommonProps(cardData),
  sectionHeadline: getTitle(cardData),
  slides: [],
  speed: cardData?.properties?.speed,
  maxResults:
    cardData?.properties?.custom?.maxResults ??
    constants.MAX_PRODUCT_LIST_LENGTH,
  // attributeIdsAll used for taxonomy AND logic, param name from product-feeds API
  attributeIdsAll:
    cardData?.properties?.valueMap?.editorial ||
    cardData?.properties?.valueMap?.editorial_taxonomy?.all,
  // attributeIds used for taxonomy OR logic, param name from product-feeds API
  attributeIds: cardData?.properties?.valueMap?.editorial_taxonomy?.any,
  excludeAttributeIds: cardData?.properties?.valueMap?.editorial_taxonomy?.not,
  featured: cardData?.properties?.custom?.selectedFeature,
});

export const prepareTranslations = (translationsStrings = {}) => ({
  ...videoTranslations(translationsStrings),
  ...getSectionHeadlineTranslation(translationsStrings),
  viewAllLabel: translationsStrings.view_all,
});

export { getGalleryUrlIngredients as getUrlIngredients } from '../helpers/carouselHelper/carouselHelper';

export const mergeProperties = ({ cardData, urls, translations, pageType }) => {
  const props = mergeGalleryProperties({
    cardData,
    urls,
    translations,
    pageType,
  });
  const viewAllLinkPath = ['sectionHeadline', 'actions', 0, 'actionText'];
  return path(viewAllLinkPath, props)
    ? assocPath(viewAllLinkPath, translations.viewAllLabel, props)
    : props;
};

export {
  getGalleryPlacements as getAnalyticsPlacements,
  getGalleryActions as getAnalyticsActions,
} from '../helpers/analytics';

export { getBuyingToolsIngredients } from './common';
