import { select, call, put, takeLatest } from 'redux-saga/effects';
import { subscribeToNotifications } from '@nike/ciclp-data-fetch-layer';
import { API_BASE_URL, CHANNEL_IDS } from '@nike/ciclp-config';
import logger from '@nike/ciclp-utils/logger';
import {
  languageCodeSelector,
  marketplaceSelector,
} from '../store/state/stateSelector';
import { notifiMeApiActions } from '../actions';

// goes over all destinations in nodes and requests urls by ingredient
export const requestNotifyMeSubscription = function* ({ payload }) {
  const language = yield select(languageCodeSelector);
  const marketplace = yield select(marketplaceSelector);
  try {
    const result = yield call(subscribeToNotifications, {
      fetchFn: globalThis.extendedFetch,
      params: {
        marketplace,
        language,
        consumerChannel: CHANNEL_IDS.DEFAULT,
        who: {
          email: payload.email,
        },
        what: {
          notifyOn: 'BECOMES_BUYABLE',
          resourceType: '/merch/product',
          id: payload.productId,
        },
        fetchParams: {
          timeout: 5000,
          baseUrl: API_BASE_URL,
        },
      },
    });

    if (result.id) {
      yield put(notifiMeApiActions.success(payload));
    }
  } catch (error) {
    const { name, message, stack, details } = error;
    logger.warn({
      name,
      message,
      stack,
      details,
    });
    yield put(notifiMeApiActions.error(payload));
  }
};

export const notifyMeSubscriptionSaga = function* () {
  yield takeLatest(
    notifiMeApiActions.request.type,
    requestNotifyMeSubscription,
  );
};
