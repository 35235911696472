import {
  __,
  filter,
  includes,
  invertObj,
  lensPath,
  map,
  over,
  pipe,
  prop,
} from 'ramda';

const supportedIngredientTypes = [
  'PDP',
  'GRIDWALL',
  'PAGE',
  'PDP_ID',
  'ARTICLE',
];
// Replacing PDP_ID (CMS value) with PDP_NBY (value for url-genesis)
// Replacing PAGE (CMS value) with LANDING_PAGE (value for url-genesis)
const cmsToUrlGenesis = {
  PDP_ID: 'PDP_NBY',
  PAGE: 'LANDING_PAGE',
};
// Replacing back PDP_NBY (value for url-genesis) with PDP_ID (CMS value) to match CMS
// Replacing back LANDING_PAGE (value for url-genesis) with PAGE (CMS value) to match CMS
const urlGenesisToCms = invertObj(cmsToUrlGenesis);

/**
 * Replaces paired values CMS to url-genesis and vice versa
 *
 * @param pairedValues - object like cmsToUrlGenesis or urlGenesisToCms
 * @param path - path to value
 * @return curried map function that accepts array
 */
export const replaceValues = (pairedValues, path) =>
  map(over(lensPath(path), type => pairedValues[type] || type));

/**
 * Replaces CMS type values with url-genesis type values
 *
 * @return curried function that accepts array of raw url ingredients before calling getUrlByIngredient
 */
export const prepareUrlIngredients = pipe(
  filter(pipe(prop('type'), includes(__, supportedIngredientTypes))),
  replaceValues(cmsToUrlGenesis, ['type']),
);

/**
 * Replaces url-genesis type values with CMS type values
 *
 * @return curried function that accepts array of results of calling getUrlByIngredient
 */
export const prepareUrlIngredientsResults = pipe(
  filter(Boolean),
  replaceValues(urlGenesisToCms, [0, 'type']),
);
