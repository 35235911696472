import * as actionsSource from './actions';
import * as getCardDataSelector from './store/data/getCardDataSelector';
import * as bannerSelectors from './store/data/bannerSelectors';
import * as layoutSelectors from './store/data/layoutSelectors';
import * as pageSelectors from './store/data/pageSelectors';
import * as urlSelectors from './store/data/urlSelectors';
import { getFallbackDataSelector } from './store/data/getFallbackDataSelector';
import { isHowToPage } from './store/data/isHowToSelector';
import * as routeNameSelectors from './store/router/routerSelector';
import * as stateSelectors from './store/state/stateSelector';
import * as configSelectors from './store/config/configSelector';
import * as translationsStringsSelector from './store/state/translations/translationsStringsSelector';
import * as notifyMeSelectors from './store/state/notifyMe';
import * as cloudinary from './utils/cloudinaryUtils';
import * as locationUtils from './utils/locationUtils';
import * as analyticsSelectors from './analytics/analyticsSelectors';
import * as privacySelectors from './store/state/privacy/privacySelector';
import * as storageSelectors from './store/storage/storageSelector';
import * as optimizationSelectors from './store/state/optimization/selectors';
import { optimizelySelectors } from './store/state/optimizely';
import * as shopTheLookSelectors from './store/state/shopTheLook/shopTheLookSelectors';
import { isBootstrappedSelector } from './store/state/isBootstrapped/isBootstrappedSelector';
import { errorSelector } from './store/state/error/errorSelector';
import { dataSelector } from './store/data/dataSelector';
import { measurementSelector } from './store/measurement/measurementSelectors';
import { identityStateSelector } from './store/state/identity/identitySelectors';
import { buyingToolsIngredientsSelector } from './store/data/buyingToolsSelectors';
import { localNavPlaceholderSelector } from './store/data/localNavPlaceholderSelector';
import { urlIngredientsSelector } from './store/data/urlIngredientsSelector';

export { configureStore } from './store/configureStore';
export { routeNames } from './enum/routeNames';
export { errors, logLevels } from './enum/errors';

export const selectors = {
  ...getCardDataSelector,
  ...bannerSelectors,
  ...layoutSelectors,
  ...pageSelectors,
  ...urlSelectors,
  getFallbackDataSelector,
  isHowToPage,
  dataSelector,
  measurementSelector,
  errorSelector,
  isBootstrappedSelector,
  identityStateSelector,
  buyingToolsIngredientsSelector,
  localNavPlaceholderSelector,
  urlIngredientsSelector,
  ...routeNameSelectors,
  ...stateSelectors,
  ...configSelectors,
  ...translationsStringsSelector,
  ...notifyMeSelectors,
  ...analyticsSelectors,
  ...privacySelectors,
  ...storageSelectors,
  ...optimizationSelectors,
  ...shopTheLookSelectors,
  ...optimizelySelectors,
};

export const actions = actionsSource;

export const utils = {
  ...cloudinary,
  ...locationUtils,
};

export * from './constants';

export { analyticsViewProperties, eventNames } from './analytics';

export { getCardHash } from './services/analytics/helpers';

export { getExtendedFetchFn } from './services/dataLayerWrapper/extendedFetchGenerator';
export { isErrorRoute } from './utils/routeHelpers';

// Server related methods go here
////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////
//////////////////
////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////
/////////////
////////////
////////////////////////////////
///////////////////
///////////////////
///////////////////
///////////////////////////////////////
/////////
