import mirrorCreator from 'mirror-creator';

/**
 * Reduces boilerplate by having to manually specify action types
 * separately for Req/Res/Err states for async action.
 * It generates those for you by suffixing the base action name.
 * Also completely eliminates the need for separate action types enumeration,
 * as the generated actions, both sync and async, get decorated with `.type` property
 */

export const defaultAsyncActionList = ['request', 'success', 'error'];

export const asyncActionTypes = (basename, prefix, actionList) =>
  mirrorCreator(
    actionList.map(strSuffix =>
      [basename.toUpperCase(), strSuffix.toUpperCase()].join('_'),
    ),
    { prefix: `@${prefix}/` },
  );

/**
 * Creates a map containing redux action set for an async operation
 * default 'request', 'success', 'error'
 * @param {String} basename base name of your actions
 * @param {String} prefix prefix of your actions
 * @param {Array} additionalActionList additional list of actions to create
 */
export const asyncActionsCreator = (
  basename,
  prefix,
  additionalActionList = [],
) => {
  const actionList = [...defaultAsyncActionList, ...additionalActionList];
  const types = asyncActionTypes(basename, prefix, actionList);
  const container = {};
  actionList.forEach(strAction => {
    const type = types[`${basename.toUpperCase()}_${strAction.toUpperCase()}`];
    container[strAction] = payload => ({
      type,
      payload,
    });
    container[strAction].type = type;
  });
  return container;
};

/**
 * Creates a map containing redux action given in first argument
 * @param {Array} arrActions list of action names to be exposed
 * @param {String} prefix prefix to prepend to action
 */
export const syncActionsCreator = (arrActions, prefix = '') => {
  const types = mirrorCreator(
    arrActions.map(actionName => actionName.toUpperCase()),
    { prefix: `@${prefix}/` },
  );
  const container = {};
  arrActions.forEach(actionName => {
    const type = types[actionName.toUpperCase()];
    container[actionName] = payload => ({
      type,
      payload,
    });
    container[actionName].type = type;
  });
  return container;
};
