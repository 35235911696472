import { all, call, select, put, race } from 'redux-saga/effects';
import { values, mergeAll, pipe, map, indexBy, prop } from 'ramda';

import {
  productRecommenderApiAction,
  recommendationActions,
} from '../../actions';
import { containerTypeCardSelector } from '../../store/data/getCardDataSelector';
import { fetchProductRecommender } from '../userRecommenderSaga';
import { CARD_TYPES } from '../../constants';
import {
  adjustMaxResult,
  takeAll,
  prepareProductRecommenderSlides,
} from './helpers';

export function* getProductCards() {
  const recommenderCardsSelect = yield all([
    select(containerTypeCardSelector, {
      containerType: CARD_TYPES.PRODUCT_RECOMMENDER,
    }),
    select(containerTypeCardSelector, {
      containerType: CARD_TYPES.PRODUCT_RECOMMENDER_TAXONOMY,
    }),
  ]);
  const recommenderCards = pipe(
    mergeAll,
    map(item => {
      const { taxonomies, clearance, maxResults, id } = item;
      return {
        taxonomies,
        clearance,
        maxResults,
        id,
      };
    }),
    values,
  )(recommenderCardsSelect);

  return [recommenderCards];
}

export function* refetchRecommendations(recommenderCards) {
  const { result } = yield all({
    prs: call(fetchProductRecommender, adjustMaxResult(recommenderCards)),
    result: race([
      takeAll({
        useFallback: recommendationActions.usePRSFallbackPartly.type,
        cardsWithSlides: productRecommenderApiAction.success.type,
      }),
      takeAll({
        withoutFallback: recommendationActions.withoutFallback.type,
        cardsWithSlides: productRecommenderApiAction.success.type,
      }),
    ]),
  });

  return result;
}

export function* fetchRecommendations(recommenderCards) {
  const { result } = yield all({
    prs: call(fetchProductRecommender, adjustMaxResult(recommenderCards)),
    result: race([
      takeAll({
        useFallback: recommendationActions.usePRSFallbackPartly.type,
        cardsWithSlides: productRecommenderApiAction.success.type,
      }),
      takeAll({ useFallback: recommendationActions.usePRSFallback.type }),
      takeAll({
        withoutFallback: recommendationActions.withoutFallback.type,
        cardsWithSlides: productRecommenderApiAction.success.type,
      }),
    ]),
  });

  return result;
}

const findFirstValidResult = results => results.find(result => !!result);

/**
 *    @description This saga is used for getting recommendations from the recommendations server
 *    @param {Object} recommenderCards the list of recommender cards that we have on the page
 *    @param {Boolean} isRefetch flag that indicates is it initial fetch of recommendations or we are in refetch stage
 * */

export function* getRecommendationsSaga(recommenderCards, isRefetch = false) {
  const initialRecommenderCards = indexBy(prop('id'))(recommenderCards);
  const { recommenderResults } = yield all({
    recommenderResults: call(
      isRefetch ? refetchRecommendations : fetchRecommendations,
      recommenderCards,
    ),
  });

  yield put(
    recommendationActions.putPRSSlides(
      prepareProductRecommenderSlides(
        findFirstValidResult(recommenderResults),
        isRefetch,
        initialRecommenderCards,
      ),
    ),
  );
}
