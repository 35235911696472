import {
  APIError,
  customFetch,
  renameFilterProps,
  transformParamsToString,
  ErrorMessage,
  FetchParams,
  DEFAULT_API_URL,
} from '../../common';

export const getThreads = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: Thread.RequestFilters & { fields?: string[] } & FetchParams;
}): Promise<Thread.Response> => {
  const {
    callerId,
    sort,
    count,
    anchor,
    layout,
    fields,
    fetchParams: { timeout, baseUrl = DEFAULT_API_URL } = {},
    resource,
    ...filters
  } = params;
  const paramsString = transformParamsToString({
    sort,
    count,
    anchor,
    layout,
    fields,
    filter: renameFilterProps(filters as Thread.RequestFilters),
  });
  const path = 'product_feed/threads/v2';
  const url = `${baseUrl}/${path}/${paramsString}`;
  const requestOptions = {
    method: 'GET',
    headers: {
      'nike-api-caller-id': callerId,
    },
    vipAddress: 'product_feed-threads-v2',
    path,
    timeout,
  };

  const result = await customFetch({
    fetchFn,
    url,
    resource,
    options: requestOptions,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      url,
      requestOptions,
      originalMessage: e.message,
    });
  }
};
