export type RequestValue = string | number | boolean | string[];
type RequestRecord = { [key: string]: RequestValue };
export type ParamsObj = {
  [key: string]: RequestValue | Record<string, RequestValue>;
};

const FILTER_KEY = 'filter';

const transformFilterProp = (
  params: Record<string, RequestValue> = {},
  name: string = FILTER_KEY,
): string =>
  Object.keys(params).reduce((str, key) => {
    const strStart = str ? `${str}&` : str;
    return `${strStart}${name}=${key}(${params[key].toString()})`;
  }, '');

export const transformParamsToString = (params: ParamsObj = {}): string => {
  const entries = Object.entries(params);
  const keyValuePairs = entries
    .map(([key, value]) => {
      if (value === null || value === undefined) {
        return null;
      }
      // Used for filters in thread API as they are passed as object
      if (value instanceof Object && !(value instanceof Array)) {
        return transformFilterProp(value, key);
      }
      return `${key}=${value?.toString()}`;
    })
    .filter(a => !!a);

  return entries.length ? `?${keyValuePairs.join('&')}` : '';
};

export const renameFilterProps = (filters: RequestRecord) => {
  const MAPPER = {
    collections: 'publishedContent.properties.publish.collections',
    productIds: 'productInfo.merchProduct.id',
    pageType: 'publishedContent.properties.pageType',
    subType: 'publishedContent.subType',
    excludeCollectionIds:
      'excludePublishedContent.propertiesPublishCollections',
    styleColors: 'productInfo.merchProduct.styleColor',
  } as RequestRecord;

  return Object.keys(filters).reduce(
    (acc, key: string) => ({
      ...acc,
      [(MAPPER[key] as string) || key]: filters[key],
    }),
    {},
  );
};
