import { chain, curry } from 'ramda';
import {
  ANALYTICS_TAB_INDEXES,
  CARD_ACTION_DESTINATION_TYPES,
  PRODUCT_AVAILABILITY,
  SHOP_THE_LOOK_CARD_PREFIX,
} from '../../constants';

import { analyticsProperties } from '../../analytics/constants';
import cardMethods from '../transforms/cardMethods';

export const createPlacement = ({
  cardPlacement,
  grid,
  placementIndex,
  totalPositions,
}) => ({
  ...cardPlacement,
  [analyticsProperties.POSITION_ID]: {
    mobile: grid.position?.small,
    desktop: grid.position?.large,
  },
  [analyticsProperties.PLACEMENT_ID]: placementIndex,
  [analyticsProperties.TOTAL_POSITIONS]: totalPositions,
});

export const getShopTheLookCardsIds = card =>
  card?.actionButtons?.reduce(
    (acc, button) =>
      button.destinationType ===
      CARD_ACTION_DESTINATION_TYPES.PRODUCT_COLLECTION
        ? [...acc, `${SHOP_THE_LOOK_CARD_PREFIX}${button.id}`]
        : acc,
    [],
  ) ?? [];

export const getCalculatedPlacements = card =>
  cardMethods[card?.containerType]?.getAnalyticsPlacements?.(card);

export const getCardHash = ({ id, index, hasSeveralPlacements, tabIndex }) =>
  (tabIndex ? `${tabIndex}_` : '') +
  id +
  (hasSeveralPlacements ? `_${index}` : '');

export const findBlocks = curry((layoutItems, id) => {
  const layoutItem = layoutItems?.[id];
  if (!layoutItem) {
    return [];
  }
  const isBlock = layoutItem.mode === 'block';

  return [
    ...(isBlock ? [layoutItem] : []),
    ...(layoutItem.items
      ? chain(findBlocks(layoutItems), layoutItem.items)
      : []),
  ];
});

export const createSnkrsPlacements = ({
  hasSeveralPlacements,
  hasSectionHeadline,
  cardPlacements,
  block,
  grid,
  totalPositions,
  card,
}) => {
  const snkrsPlacements = {};
  cardPlacements.forEach((cardPlacement, index) => {
    const placementGroup = cardPlacement[analyticsProperties.PLACEMENT_GROUP];
    const indexInsideTab =
      placementGroup === PRODUCT_AVAILABILITY.UPCOMING
        ? index - card.slides.length
        : index;
    const placementId = hasSectionHeadline
      ? indexInsideTab
      : indexInsideTab + 1;
    const id = getCardHash({
      id: block.id,
      index: placementId,
      hasSeveralPlacements,
      tabIndex: ANALYTICS_TAB_INDEXES[placementGroup],
    });
    snkrsPlacements[id] = createPlacement({
      cardPlacement,
      grid,
      placementIndex: placementId,
      totalPositions,
    });
  });

  return snkrsPlacements;
};

export const createPlacements = ({
  hasSeveralPlacements,
  hasSinglePlacement,
  hasSectionHeadline,
  cardPlacements,
  block,
  grid,
  totalPositions,
  increasePlacementIndex,
}) => {
  const newPlacements = {};
  if (hasSeveralPlacements || hasSinglePlacement) {
    (hasSeveralPlacements ? cardPlacements : [cardPlacements]).forEach(
      (cardPlacement, index) => {
        const innerPlacementIndex = hasSectionHeadline ? index : index + 1;
        const calculatedPlacementIndex = cardPlacement.isSectionHeadline
          ? 0
          : increasePlacementIndex();

        const id = getCardHash({
          id: block.id,
          index: innerPlacementIndex,
          hasSeveralPlacements,
        });

        newPlacements[id] = createPlacement({
          cardPlacement,
          grid,
          placementIndex: calculatedPlacementIndex,
          totalPositions,
        });
      },
      cardPlacements,
    );
  }

  return newPlacements;
};

export const createStlPlacements = ({
  cardPlacements,
  block,
  grid,
  totalPositions,
  increasePlacementIndex,
}) => {
  const stlPlacements = {};
  cardPlacements?.forEach((cardPlacement, index) => {
    // _0 placement is reserved for the section headline
    const id = getCardHash({
      id: block.id,
      index: index + 1,
      hasSeveralPlacements: true,
    });
    stlPlacements[id] = createPlacement({
      cardPlacement,
      grid,
      placementIndex: increasePlacementIndex(),
      totalPositions,
    });
  });

  return stlPlacements;
};
