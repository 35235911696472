import { pick, propEq } from 'ramda';
import striptags from 'striptags';
import {
  DEFAULT_TEXT_CARD_BODY,
  DEFAULT_TEXT_CARD_SUBTITLE,
  DEFAULT_TEXT_CARD_TITLE,
} from '../../../store/data/constants';
import { URL_INGREDIENTS } from '../helpers/constants';
import {
  prepareTextData,
  sanitizeRichText,
  prepareGenericCardData,
  extractIngredientsFromList,
  mergeLinkWithIngredients,
  extractBuyingIngredientsFromList,
} from '../helpers';
import { analyticsProperties } from '../../../analytics/constants';
import {
  getActions,
  getClickActivity,
  getCardLinkActions,
  getCommonAnalyticsProps,
} from '../helpers/analytics';
import { getCommonProps, getButtonTitle } from '../helpers/commonHelpers';

export const prepareCardData = textCard => {
  const genericCardData = prepareGenericCardData(textCard);
  const richTextLinks = textCard?.properties?.richTextLinks;

  let bodyText = textCard?.properties?.body ?? '';
  const urls = richTextLinks
    .filter(link => link.type !== 'URL')
    .map(url => ({
      ...url,
      urlIngredients: pick(URL_INGREDIENTS)(url),
    }));
  if (bodyText && richTextLinks) {
    bodyText = bodyText.replace(/data-id/g, 'data-analytics-action-id');
  }

  const titleProps = prepareTextData(textCard, 'title');
  const subtitleProps = prepareTextData(textCard, 'subtitle');
  const bodyProps = {
    text: sanitizeRichText(bodyText, ''),
    ...(textCard?.properties?.style?.properties?.body ?? {}),
  };
  const backgroundColor =
    textCard?.properties?.style?.defaultStyle?.backgroundColor ?? 'transparent';

  return {
    ...genericCardData,
    ...getCommonProps(textCard),
    backgroundColor,
    urls,
    bodyProps: {
      ...DEFAULT_TEXT_CARD_BODY,
      fontSizeSet: !!bodyProps.fontSize,
      ...bodyProps,
    },
    titleProps: {
      ...DEFAULT_TEXT_CARD_TITLE,
      ...titleProps,
    },
    subtitleProps: {
      ...DEFAULT_TEXT_CARD_SUBTITLE,
      ...subtitleProps,
    },
  };
};

export { prepareTranslations } from './common';

export const mergeProperties = ({
  cardData,
  urls,
  buyingToolsList,
  countryCode,
  languageMappings = {},
}) => {
  const {
    actionButtons,
    bodyProps,
    urls: richTextLinks,
    ...restCardData
  } = cardData;
  const bodyPropsWithRichText = richTextLinks.reduce((resultText, link) => {
    const { url = '', id } = mergeLinkWithIngredients({
      action: link,
      urls,
      property: 'url',
    });
    return resultText.replace(
      new RegExp(`(<a[^>]+data-analytics-action-id="${id}"[^>]+href=")`),
      `$1${url}`,
    );
  }, bodyProps.text);
  const { urlParam } = languageMappings;
  return {
    ...mergeLinkWithIngredients({
      action: {
        countryCode,
        language: urlParam,
        ...restCardData,
      },
      urls,
    }),
    bodyProps: {
      ...bodyProps,
      text: sanitizeRichText(bodyPropsWithRichText, ''),
    },
    actionButtons: actionButtons.map(btn => {
      btn.countryCode = countryCode;
      btn.language = urlParam;
      return mergeLinkWithIngredients({
        action: btn,
        urls,
        property: 'destinationId',
        buyingToolsList,
      });
    }),
  };
};

export const getUrlIngredients = card => [
  ...extractIngredientsFromList([
    { urlIngredients: card?.urlIngredients },
    ...card?.actionButtons,
  ]),
  ...extractIngredientsFromList(card?.urls),
];

export const getAnalyticsPlacements = card => ({
  ...getCommonAnalyticsProps(card),
  [analyticsProperties.CTA_TITLE]:
    striptags(card.titleProps.text) || striptags(getButtonTitle(card)),
});

export const getAnalyticsActions = card => {
  const bodyText = card.bodyProps.text;
  const richTextLinks = (
    bodyText.match(/<a[^>]+data-analytics-action-id=[^>]+>.+?<\/a>/g) ?? []
  ).map((link, index) => {
    const [, id, text] =
      link.match(/<a[^>]+data-analytics-action-id="([^"]+)"[^>]+>(.+?)<\/a>/) ??
      [];
    const url = card.urls.find(propEq('id', id));
    return {
      id,
      [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
        url?.type ?? 'url',
        index + 1,
        text,
      ),
    };
  });
  const actionButtons = getActions(
    card.actionButtons,
    richTextLinks.length + 1,
  );

  return [...getCardLinkActions(card), ...richTextLinks, ...actionButtons];
};

export const getBuyingToolsIngredients = card =>
  extractBuyingIngredientsFromList(card?.actionButtons);
