import {
  pipe,
  prop,
  defaultTo,
  chain,
  when,
  map,
  filter,
  assocPath,
} from 'ramda';
import {
  getPDPUrl,
  mergeLinkWithIngredients,
  extractIngredientsFromList,
} from '../url';
import { getTitle, getContainerType, getCommonProps } from '../commonHelpers';
import { getCarouselTitleWithFallback } from '../carouselTitleHelpers';
import cardMethods from '../../cardMethods';
import {
  CARD_TYPES,
  DYNAMIC_CONTENT_SUPPORTED_CARD_TYPES,
} from '../../../../constants';

/**
 *    @description merges carousel data with translated labels and set title fallback if needed (case with empty title for top trending)
 *    @param {Object} cardData prepared component data (required)
 *    @param {Object} translations  object with filled in labels for specific component (required)
 *    @returns {Object} mergedData returns data for product carousel with fallback title and translated labels
 *    getCarouselWithTitle: ({ cardData, translations }) => mergedData,
 * */
export const getCarouselWithTitle = ({ cardData = {}, translations = {} }) => ({
  ...cardData,
  ...translations,
  sectionHeadline: {
    ...cardData.sectionHeadline,
    title: getCarouselTitleWithFallback({ cardData, translations }),
  },
});

/**
 *    @description merges together URLs, translations and card data into final props for the component (for section headline and all carousel titles)
 *    @param {Object} cardData prepared component data (required)
 *    @param {Object} translations object with filled in labels for specific component
 *    @param {[Object, string][]} urls array with URL ingredients and corresponding URL
 *    @returns {Object} mergedData data merged with translated labels and URLs instead of ingredients, finally ready props for component
 *    mergeSectionHeadlineTitle: ({ cardData, translations, urls }) => mergedData,
 * */
export const mergeSectionHeadlineTitle = ({
  cardData,
  /**
   * Refactoring note: It is simultaneously used as a helper in various cards and as a standalone `mergeProperties` method for section headline.
   * As a result in one place cardData argument is a result of `prepareCardData` and in others it is not a cardData but card's `cardData.sectionHeadline`
   * No plan to fix now. More details: CICLP-3100
   */
  translations,
  urls,
}) => ({
  ...cardData,
  sectionLabel: translations?.sectionLabel,
  actions: cardData?.actions?.map(action =>
    mergeLinkWithIngredients({ action, urls, property: 'destinationId' }),
  ),
});

/**
 *    @description merges together URLs, translations and card data into final props for the product container (external collection, top trending and product recommended)
 *    @param {Object} cardData prepared component data (required)
 *    @param {Object} translations object with filled in labels for specific component
 *    @param {[Object, string][]} urls array with URL ingredients and corresponding URL
 *    @param {string} countryCode code for PDP URLs manual generation
 *    @param {string} languageRegion for PDP URLs manual generation
 *    @param {string} languageCountryPath for PDP URLs manual generation
 *    @param {Object} languageMappings
 *    @returns {Object} mergedProductContainerData data merged with translated labels and URLs instead of ingredients, finally ready props for component
 *    mergeCarouselProperties: ({ cardData, translations, urls, countryCode, languageRegion, languageCountryPath }) => mergedProductContainerData,
 * */
export const mergeCarouselProperties = ({
  cardData,
  translations,
  urls,
  countryCode,
  languageRegion,
  languageCountryPath,
  languageMappings,
}) => {
  const { slides = [], sectionHeadline, ...restCardData } = cardData;
  return {
    ...restCardData,
    ...translations,
    sectionHeadline: mergeSectionHeadlineTitle({
      cardData: sectionHeadline,
      translations,
      urls,
    }),
    localeForCurrency: languageMappings?.hreflang,
    slides: slides.map(slide => ({
      ...slide,
      country: countryCode,
      // ProductCard link prop URL from urlIngredients
      link: {
        href: pipe(prop('urlIngredients'), urlIngredients =>
          getPDPUrl({
            urlIngredients,
            pdpType: prop('pdpType')(slide),
            urls,
            countryCode,
            languageRegion,
            languageCountryPath,
          }),
        )(slide),
        title: slide.title,
      },
      url: pipe(prop('urlIngredients'), urlIngredients =>
        getPDPUrl({
          urlIngredients,
          pdpType: prop('pdpType')(slide),
          urls,
          countryCode,
          languageRegion,
          languageCountryPath,
        }),
      )(slide),
    })),
  };
};

/**
 *    @description populates card with url ingredients
 *    @param {Object} card prepared component data (required)
 *    @returns {Object} url ingredients data
 * */

export const getGalleryUrlIngredients = (card = {}) => [
  ...extractIngredientsFromList([
    ...(card?.sectionHeadline?.actions ?? []),
    ...(card?.featuredCard
      ? [card?.featuredCard, ...(card?.featuredCard?.actionButtons ?? [])]
      : []),
  ]),
  ...pipe(
    defaultTo([]),
    chain(slide => [
      ...extractIngredientsFromList([slide, ...(slide?.actionButtons ?? [])]),
      ...extractIngredientsFromList(slide?.urls),
    ]),
  )(card?.slides),
];

/**
 *    @description prepare slides data for a card, populate card with headline
 *    @param {Object} cardData prepared component data (required)
 *    @param {Object} data initial card data (required)
 *    @param {String} containerType one of CARD_TYPES
 *    @returns {Object} preparedFilmstripCardData, finally ready props for component
 * */

export const prepareGalleryCardData = (cardData, data, containerType) => {
  const sectionHeadline = getTitle(cardData);

  const slides = pipe(
    // disabling slideshow video slides for now because we can not maintain aspect ratio for video
    when(
      () => containerType === CARD_TYPES.SLIDESHOW,
      filter(
        slideId => getContainerType(data.nodes[slideId]) === CARD_TYPES.IMAGE,
      ),
    ),
    map(slideId =>
      cardMethods[getContainerType(data.nodes[slideId])]?.prepareCardData(
        data.nodes[slideId],
        data,
        {
          withDefaultImageHeight: containerType !== CARD_TYPES.FILMSTRIP,
        },
      ),
    ),
  )(cardData?.nodes ?? []);

  return {
    ...getCommonProps(cardData),
    fallbacks: cardData?.properties?.fallbacks,
    sectionHeadline,
    slides,
    speed: cardData?.properties?.speed,
    loop: cardData?.properties?.loop,
  };
};

/**
 *    @description merges together URLs, translations and card data into final props for the filmstrip (filmstrip, related and slideshow)
 *    @param {Object} cardData prepared component data (required)
 *    @param {[Object, string][]} urls array with URL ingredients and corresponding URL
 *    @param {string} pageType type of the page (article or thread)
 *    @param {Object} translations list of translations
 *    @param {Object} restOptions rest rendering options
 *    @returns {Object} mergedFilmstripProperties data merged with translated labels and URLs instead of ingredients, finally ready props for component
 *    mergeGalleryProperties: ({ cardData, urls }) => mergedFilmstripProperties,
 * */

export const mergeGalleryProperties = ({
  cardData = {},
  urls = [],
  pageType,
  translations,
  ...restOptions
}) => {
  const {
    slides = [],
    sectionHeadline,
    featuredCard,
    ...restCardData
  } = cardData;
  return {
    translations,
    ...restCardData,
    pageType,
    sectionHeadline: mergeSectionHeadlineTitle({
      cardData: sectionHeadline,
      translations,
      urls,
    }),
    ...(featuredCard && {
      featuredCard: cardMethods[featuredCard?.containerType]?.mergeProperties({
        cardData: featuredCard,
        urls,
        translations,
        pageType,
        ...restOptions,
      }),
    }),
    slides: slides.map(slide =>
      slide.containerType
        ? {
            ...translations,
            ...cardMethods[slide?.containerType]?.mergeProperties({
              cardData: slide,
              urls,
              translations,
              pageType,
              ...restOptions,
            }),
          }
        : slide,
    ),
  };
};

const prepareEditorialSlide = (
  { coverCard, tagging, publish },
  preferredOrientation = {
    small: 'landscape',
    large: 'landscape',
    medium: 'landscape',
  },
) => ({
  ...cardMethods[getContainerType(coverCard)].prepareCardData(
    assocPath(
      ['properties', 'preferredOrientation'],
      preferredOrientation,
      coverCard,
    ),
  ),
  taxonomyAttributes: tagging?.taxonomyAttributes,
  cardLinkId: `${coverCard?.id}_card-link`,
  destinationType: 'ARTICLE',
  urlIngredients: {
    type: 'ARTICLE',
    pageId: publish?.collections?.[0],
  },
});

export const prepareDynamicContentCarousel = (threads, preferredOrientation) =>
  threads
    .filter(thread =>
      DYNAMIC_CONTENT_SUPPORTED_CARD_TYPES.includes(
        getContainerType(thread?.publishedContent?.properties?.coverCard),
      ),
    )
    .map(thread =>
      prepareEditorialSlide(
        thread?.publishedContent?.properties,
        preferredOrientation,
      ),
    );
