import { call, put, select } from 'redux-saga/effects';
import {
  ABOVE_THE_FOLD_OPTIMIZATION_LOCATION_NAME,
  ADOBE_TARGET_COOKIE_NAME,
  DEFAULT_OPTIMIZATION_LOCATION_NAME,
} from '@nike/ciclp-config';
import { applyPatch } from '@nike/ciclp-utils/patches';
import logger from '@nike/ciclp-utils/logger';
import { HYDRATE } from 'next-redux-wrapper';
import { setCookie } from '@nike/ciclp-utils/cookies';
import { optimizationActions } from '../../actions';
import {
  canonicalUrlSelector,
  languageCodeSelector,
  marketplaceSelector,
  pageIdSelector,
} from '../../store/state/stateSelector';
import { getOptimizationLocations } from './getOptimizationLocations';

export const resetAtCookie = function (pagePath) {
  setCookie(ADOBE_TARGET_COOKIE_NAME, `active=0&page=${pagePath}`);

  logger.info({
    message: 'Optimization experiment deactivated',
    details: {
      experiment: ABOVE_THE_FOLD_OPTIMIZATION_LOCATION_NAME,
      page: pagePath,
    },
  });
};

export const saveAnalyticsFromATCookie = function* (atCookie) {
  const { tnta } = atCookie;
  const cookieLocations = [
    {
      analytics: tnta,
    },
  ];

  yield put(optimizationActions.loadLocations(cookieLocations));
};

export const getAboveTheFoldOptimizationLocations = function* () {
  const pageUrl = yield select(canonicalUrlSelector);

  return yield call(
    getOptimizationLocations,
    ABOVE_THE_FOLD_OPTIMIZATION_LOCATION_NAME,
    {
      appPageUrl: pageUrl,
    },
  );
};

export const getDefaultOptimizationLocations = function* () {
  const pageId = yield select(pageIdSelector);
  const country = yield select(marketplaceSelector);
  const language = yield select(languageCodeSelector);

  return yield call(
    getOptimizationLocations,
    DEFAULT_OPTIMIZATION_LOCATION_NAME,
    {
      appPageId: pageId,
      appCountry: country,
      appLanguage: language,
    },
  );
};

export const applyVariationPatch = function* (variationId) {
  const state = yield select();
  const storePatch = state.storePatches[variationId];
  if (!storePatch) {
    return;
  }
  try {
    const patchedState = applyPatch(state, storePatch);
    yield put({ type: HYDRATE, payload: patchedState });
  } catch (err) {
    logger.warn({
      ...err,
      name: 'Failed to apply store patch',
      details: {
        variationId,
      },
    });
  }
};
