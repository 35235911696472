import { getCommonProps } from '../helpers/commonHelpers';
import { CARD_TYPES } from '../../../constants';

export const prepareCardData = cardData => {
  return {
    ...getCommonProps(cardData),
    containerType: CARD_TYPES.INTERESTS,
    title: cardData?.properties?.title,
    subtitle: cardData?.properties?.subtitle,
    maxResults: cardData?.properties?.custom?.maxResults,
  };
};

export {
  getBuyingToolsIngredients,
  getAnalyticsActions,
  getAnalyticsPlacements,
  getUrlIngredients,
  mergeProperties,
  prepareTranslations,
} from './common';
