import { getSegmentOptions, getSegmentPayload } from './payloadStubs/payload';
import { eventNames } from './constants';
import {
  eventNameSelector,
  pageNameSelector,
  analyticsPageTypeSelector,
} from './analyticsSelectors';

export const createPageView = nextState => {
  const options = getSegmentOptions({ eventName: eventNames.PAGE_VIEWED });
  const properties = getSegmentPayload({
    eventName: eventNames.PAGE_VIEWED,
    state: nextState,
  });
  const pageName = pageNameSelector(nextState);
  const pageCategory = analyticsPageTypeSelector(nextState);

  return {
    pageName,
    pageCategory,
    options,
    properties,
  };
};

export const createTrackEvent = (action, nextState) => {
  const name =
    action?.payload?.eventName ??
    eventNameSelector(nextState, action?.payload) ??
    eventNames.CLICK;
  const options = getSegmentOptions({ eventName: name });
  const properties = getSegmentPayload({
    eventName: name,
    state: nextState,
    eventData: action?.payload,
  });

  return {
    name,
    options,
    properties,
  };
};
