import { formatCurrency } from '@nike/ux-tread-currency';

import {
  MEMBER_EXCLUSIVE_COLLECTION_ID,
  NIKE_BY_YOU_STYLE_TYPE,
  PRICE_MAXIMUM_FRACTION_DIGITS,
  PRICE_MINIMUM_FRACTION_DIGITS,
} from './constants';

export const isNikeByYouProduct = productCard => {
  return (
    productCard?.productInfo?.[0]?.merchProduct?.styleType ===
    NIKE_BY_YOU_STYLE_TYPE
  );
};

export const isMemberExlcusiveProduct = productCard => {
  return (productCard?.collectionsv2?.collectionTermIds ?? []).includes(
    MEMBER_EXCLUSIVE_COLLECTION_ID,
  );
};

export const formatPrice = (price, locale = 'en-US', currency = '') => {
  if (!price) {
    return '';
  }
  return formatCurrency({
    price: price.toString(),
    localeForCurrency: locale,
    currency,
    minimumFractionDigits: PRICE_MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: PRICE_MAXIMUM_FRACTION_DIGITS,
  });
};
