import { head, last, map, pipe, sort, uniq } from 'ramda';
import { getCommonAnalyticsProps } from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';
import { SIZE_CHART_COL_TYPES } from '../../../enum/componentAttributes';

const isMeasurement = item =>
  item.type === SIZE_CHART_COL_TYPES.MEASUREMENT && item.system !== 'UNIVERSAL';

const getSystemHash = measurement =>
  pipe(
    map(header => header.system),
    uniq,
    arr => arr.sort().join('/'),
  )(measurement);

export const getToggleOption = (localizationStrings, measurement = {}) => ({
  id: measurement?.key,
  label:
    localizationStrings?.find?.(str => str.key === measurement?.label)?.value ??
    measurement?.label,
  value: measurement?.system,
});

const getHeader = ({ key, system, type }, localizationStrings) => ({
  key,
  ...(isMeasurement({ type, system }) && { system }),
  value: localizationStrings?.find?.(str => str.key === key)?.value ?? key,
});

export const prepareCardData = (data, { nodes }) => ({
  ...getCommonProps(data),
  title: data?.properties?.title,
  description: data?.properties?.subtitle,
  sizeCharts:
    nodes?.[data?.id]?.properties?.custom?.size_charts?.map(table => {
      const sizes = sort((a, b) => a.row - b.row, table.sizes);
      const localizationStrings =
        nodes?.[data?.id]?.properties?.custom?.localizationStrings;
      const measurement = table.headers?.filter(isMeasurement).map(m => ({
        ...m,
        label:
          table?.[
            `toggleKey${m?.system
              .toLowerCase()
              .replace(/(^|\s)\S/g, l => l.toUpperCase())}`
          ],
      }));

      return {
        id: table.id,
        measurements: !!measurement?.length && {
          systemToggle: getSystemHash(measurement),
          values: [
            getToggleOption(localizationStrings, head(measurement)),
            getToggleOption(localizationStrings, last(measurement)),
          ],
        },
        data: table.headers.map(header => [
          getHeader(header, localizationStrings),
          ...sizes.map((size, index) => {
            let cellData;
            if (header.type === SIZE_CHART_COL_TYPES.RETAIL_SIZE) {
              cellData = { value: size.size };
            }
            if (header.type === SIZE_CHART_COL_TYPES.CONVERSION) {
              const value = size.conversions.find(
                c => c.header === header.key,
              )?.convertedSize;
              cellData = { value };
            }
            if (header.type === SIZE_CHART_COL_TYPES.MEASUREMENT) {
              const value = size.measurements.find(
                m => m.unit === header.system && header.key === m.header,
              )?.measurement;
              cellData = {
                ...(header.system !== 'UNIVERSAL' && {
                  system: header.system,
                }),
                value,
              };
            }
            return {
              key: `${header.key}_${index}`,
              ...cellData,
            };
          }),
        ]),
      };
    }) ?? [],
});

export { getUrlIngredients, getBuyingToolsIngredients } from './common';

export const prepareTranslations = translationStrings => ({
  defaultTitle: translationStrings?.size_chart_title,
});

export const mergeProperties = ({ cardData, translations }) => ({
  ...cardData,
  title: cardData.title || translations.defaultTitle,
});

export const getAnalyticsPlacements = getCommonAnalyticsProps;
