import { all, call } from 'redux-saga/effects';

// Same statuses as Promise.allSettled() returns, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
export const ALL_SETTLED_STATUSES = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export const allSettled = (effects = []) =>
  all(
    effects.map(effect =>
      call(function* settle() {
        try {
          return {
            status: ALL_SETTLED_STATUSES.FULFILLED,
            value: yield effect,
          };
        } catch (err) {
          return { status: ALL_SETTLED_STATUSES.REJECTED, reason: err };
        }
      }),
    ),
  );
