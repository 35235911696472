import { getGalleryActions, getGalleryPlacements } from '../helpers/analytics';
import { getSectionHeadlineTranslation } from '../helpers';
import { prepareGalleryCardData } from '../helpers/carouselHelper';
import { CARD_TYPES } from '../../../constants';
import { prepareTranslations as videoTranslations } from './video';

export const prepareCardData = (cardData, data) =>
  prepareGalleryCardData(cardData, data, CARD_TYPES.SLIDESHOW);

export const prepareTranslations = translationsStrings => ({
  ...getSectionHeadlineTranslation(translationsStrings),
  ...videoTranslations(translationsStrings),
});

export {
  getGalleryUrlIngredients as getUrlIngredients,
  mergeGalleryProperties as mergeProperties,
} from '../helpers/carouselHelper/carouselHelper';

export const getAnalyticsPlacements = card => getGalleryPlacements(card);

export { getGalleryActions as getAnalyticsActions };

export { getBuyingToolsIngredients } from './common';
