import { createSelector } from 'reselect';
import { translationStringsSelector } from '../translations/translationsStringsSelector';
import {
  countryCodeSelector,
  languageMappingsSelector,
  languageRegionSelector,
} from '../stateSelector';

const getDialogLink = (label, href = '') =>
  label
    ? `<a href=${href} class="ncss-cta-primary-dark underline" aria-label=${label}>${label}</a>`
    : '';

const getDialogBodyTemplate = ({
  template = '',
  termsOfUseUrl = '',
  termsOfUseLabel = '',
  privacyPolicyUrl = '',
  privacyPolicyLabel = '',
}) =>
  `${template
    .replace(
      '[privacyLink]',
      getDialogLink(privacyPolicyLabel, privacyPolicyUrl),
    )
    .replace('[termsOfUseLink]', getDialogLink(termsOfUseLabel, termsOfUseUrl))
    .trimRight()}`;

const getLangRegionPath = (countryCode, languageRegion) =>
  languageRegion === 'us' ? '' : `${countryCode}/${languageRegion}`;

export const getNotifyMeCommonPropsSelector = createSelector(
  countryCodeSelector,
  languageRegionSelector,
  languageMappingsSelector,
  translationStringsSelector,
  (countryCode, languageRegion, languageMappings, translationsStrings) => {
    return {
      notifyMeDialog: {
        dialogTitle: translationsStrings.notify_me_modal_title,
        dialogText: getDialogBodyTemplate({
          template: translationsStrings.notify_me_modal_text,
          termsOfUseUrl: `https://agreementservice.svs.${
            process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN
          }/${getLangRegionPath(
            countryCode,
            languageRegion,
          )}/rest/agreement?agreementType=termsOfUse&uxId=com.nike.commerce.nikedotcom.web&country=${countryCode}&language=${
            languageMappings?.language
          }&requestType=redirect`,
          termsOfUseLabel: translationsStrings.terms_of_use_label,
          privacyPolicyUrl: `https://agreementservice.svs.${
            process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN
          }/${getLangRegionPath(
            countryCode,
            languageRegion,
          )}/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.commerce.nikedotcom.web&country=${countryCode}&language=${
            languageMappings?.language
          }&requestType=redirect`,
          privacyPolicyLabel: translationsStrings.privacy_policy_link_label,
        }),
        emailLabel: translationsStrings.email_address_label,
        cancelLabel: translationsStrings.cancel_label,
        submitLabel: translationsStrings.submit_label,
        closeLabel: translationsStrings.close_label,
        postSubmitDialogText:
          translationsStrings.notify_me_thank_you_window_text,
        postSubmitDialogTitle:
          translationsStrings.notify_me_thank_you_window_title,
        notifyMeLabel: translationsStrings.notify_me_label,
        shopLabel: translationsStrings.shop_label,
        soldOutLabel: translationsStrings.sold_out_label,
        formError: translationsStrings.notify_me_form_error,
      },
      labels: {
        notifyMeLabel: translationsStrings.notify_me_label,
        shopLabel: translationsStrings.shop_label,
        soldOutLabel: translationsStrings.sold_out_label,
        previousPage: translationsStrings.pagination_previous,
        nextPage: translationsStrings.pagination_next,
      },
    };
  },
);
