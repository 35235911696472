import { values, flatten, filter, pipe } from 'ramda';
import {
  CARD_TYPES,
  CARD_DYNAMIC_TYPES,
  GALLERY_CARD_TYPES,
  CARD_ACTION_DESTINATION_TYPES,
} from '../../constants';

export const isInsetColumn = ({ offset }) =>
  offset.large === 2 && offset.medium === 2 && offset.small === 0;

export const hasSlideshowWithInsetColumn = (column, cardType) =>
  isInsetColumn(column) && CARD_TYPES.SLIDESHOW === cardType;

export const hasGalleriesOrDynamicCards = cardType =>
  values(CARD_DYNAMIC_TYPES).includes(cardType) ||
  GALLERY_CARD_TYPES.includes(cardType);

const CARD_TYPES_PROPRTIES_MAP = {
  [CARD_TYPES.IMAGE]: ({
    landscapeURL,
    portraitURL,
    portraitFocalPoint,
    landscapeFocalPoint,
    titleProps,
  }) => ({
    landscapeURL,
    portraitURL,
    portraitFocalPoint,
    landscapeFocalPoint,
    titleProps,
  }),
  [CARD_TYPES.VIDEO]: ({
    portraitPosterUrl,
    landscapePosterUrl,
    titleProps,
  }) => ({
    landscapeURL: landscapePosterUrl,
    portraitURL: portraitPosterUrl,
    titleProps,
  }),
};

export const getFallbackProperties = ({ titleProps }) => ({ titleProps });

export const getShopTheLookImageProperties = ({ containerType, ...card }) =>
  CARD_TYPES_PROPRTIES_MAP[containerType]?.(card) ??
  getFallbackProperties(card);

export const getCardFromGallery = (card, imageCardId) =>
  card?.slides.find(slide => slide.id === imageCardId);

const getCollectionShape = (card, galleryCardId, action) => ({
  originalCardId: card.id,
  galleryCardId,
  actionId: action.id,
  collectionId: action.productCollectionId,
});

const isProductCollection = type =>
  type === CARD_ACTION_DESTINATION_TYPES.PRODUCT_COLLECTION;

export const getCollectionsFromCard = (card, galleryCardId) => {
  const cardActions =
    card?.actionButtons
      ?.filter(({ destinationType }) => isProductCollection(destinationType))
      .map(action => getCollectionShape(card, galleryCardId, action)) ?? [];

  const cardLinkActions = [
    isProductCollection(card.destinationType) && [
      getCollectionShape(card, galleryCardId, {
        id: card.cardLinkId,
        productCollectionId: card.cardLinkCollectionId,
      }),
    ],
  ];

  const slidesActions =
    card.slides?.map(slide => getCollectionsFromCard(slide, card.id)) ?? [];

  const actions = [...cardActions, ...slidesActions, ...cardLinkActions];

  return pipe(filter(Boolean), flatten)(actions);
};
