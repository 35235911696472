import striptags from 'striptags';
import { DEFAULT_TEXT_LOCATION } from '../../../store/data/constants';
import { MEDIA_QUERIES } from '../../../constants';
import {
  prepareGenericCardData,
  mergeLinkWithIngredients,
  extractIngredientsFromList,
  getImageOverride,
  extractBuyingIngredientsFromList,
  getCommonProps,
  getButtonTitle,
} from '../helpers';
import { analyticsProperties } from '../../../analytics/constants';
import {
  getActions,
  getCardLinkActions,
  getCommonAnalyticsProps,
} from '../helpers/analytics';
import { resolveVideoHeight } from '../helpers/video';

export const prepareCardData = videoCard => {
  const genericCardData = prepareGenericCardData(videoCard);
  const loop = videoCard?.properties?.loop;
  const defaultVideoId = videoCard?.properties?.videoId;
  const defaultAspectRatio = videoCard?.properties?.aspectRatio;
  const videoId = videoCard?.properties?.landscape?.videoId ?? defaultVideoId;
  const landscapeAspectRatio =
    videoCard?.properties?.landscape?.aspectRatio ?? defaultAspectRatio;
  const portraitVideoId = videoCard?.properties?.portrait?.videoId;
  const portraitAspectRatio =
    videoCard?.properties?.portrait?.aspectRatio ?? landscapeAspectRatio;
  const controlOptions = videoCard?.properties?.controlOptions;
  const autoPlay = videoCard?.properties?.autoPlay;
  const startImage = {
    ...videoCard?.properties?.startImage,
    preferredOrientation: videoCard?.properties?.preferredOrientation,
  };
  const portraitPosterUrl = getImageOverride(
    startImage,
    MEDIA_QUERIES.mobile,
    startImage?.portrait?.url,
  );
  const landscapePosterUrl = getImageOverride(
    startImage,
    MEDIA_QUERIES.desktop,
    startImage?.landscape?.url,
  );
  const actions = videoCard?.properties?.actions ?? [];
  const textLocation =
    actions.length ||
    videoCard?.properties?.title ||
    videoCard?.properties?.subtitle
      ? videoCard?.properties?.style?.defaultStyle?.textLocation
      : DEFAULT_TEXT_LOCATION;

  const { imageHeight, assetsAspectRatios } = resolveVideoHeight(
    {
      landscape: landscapeAspectRatio,
      portrait: portraitAspectRatio,
    },
    videoCard.properties?.custom?.imageHeight,
  );
  return {
    ...genericCardData,
    ...getCommonProps(videoCard),
    loop,
    videoId,
    portraitVideoId,
    assetsAspectRatios,
    controlOptions,
    autoPlay,
    portraitPosterUrl,
    landscapePosterUrl,
    textLocation,
    imageHeight,
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  watchCtaButtonText: translationsStrings.video_watch_button_label,
});

export const mergeProperties = ({
  cardData,
  translations,
  urls = [],
  languageMappings = {},
  pageType,
  buyingToolsList,
  countryCode,
}) => {
  const { actionButtons, ...restCardData } = cardData;
  const { urlParam, langRegion } = languageMappings;
  return {
    ...mergeLinkWithIngredients({
      action: {
        countryCode,
        language: urlParam,
        ...restCardData,
      },
      urls,
    }),
    ...translations,
    locale: langRegion,
    language: urlParam,
    pageType,
    actionButtons: actionButtons.map(btn => {
      btn.countryCode = countryCode;
      btn.language = urlParam;

      return mergeLinkWithIngredients({
        action: btn,
        urls,
        property: 'destinationId',
        buyingToolsList,
      });
    }),
  };
};

export const getUrlIngredients = card =>
  extractIngredientsFromList([
    { urlIngredients: card?.urlIngredients },
    ...card?.actionButtons,
  ]);

export const getAnalyticsPlacements = card => ({
  ...getCommonAnalyticsProps(card),
  [analyticsProperties.VIDEO_ID]: {
    mobile: card.portraitVideoId,
    desktop: card.videoId,
  },
  [analyticsProperties.CTA_TITLE]:
    striptags(card.titleProps.text) || striptags(getButtonTitle(card)),
});

export const getAnalyticsActions = card => [
  ...getCardLinkActions(card),
  ...getActions(card.actionButtons, 1),
];

export const getBuyingToolsIngredients = card =>
  extractBuyingIngredientsFromList(card?.actionButtons);
