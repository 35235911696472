import { createMiddleware } from 'redux-beacon';
import { FEATURE_FLAGS, isWeb, getSegmentKey } from '@nike/ciclp-config';

import { analyticsActions } from '../actions';
import { featureFlagsSelector } from '../store/state/stateSelector';
import { createPageView, createTrackEvent } from './eventCreators';

const trackPageView = (action, prevState, nextState) => {
  const {
    pageName,
    pageCategory,
    properties,
    options = {},
  } = createPageView(nextState);
  return { pageCategory, pageName, properties, options, type: action.type };
};

const trackEvent = (action, prevState, nextState) => {
  const {
    name,
    properties,
    options = {},
  } = createTrackEvent(action, nextState);
  return { name, options, properties, type: action.type };
};

const eventsMap = {
  [analyticsActions.page.type]: trackPageView,
  [analyticsActions.track.type]: trackEvent,
  [analyticsActions.init.type]: event => event,
};

export const analyticsClientTarget = events => {
  events.forEach(event => {
    const { pageCategory, pageName, properties, options, name } = event;
    switch (event.type) {
      case analyticsActions.page.type:
        window.webShellClient.analytics.page(
          pageCategory,
          pageName,
          properties,
          options,
        );
        break;
      case analyticsActions.track.type:
        window.webShellClient.analytics.track(name, properties, options);
        break;
      case analyticsActions.init.type:
        window.webShellClient.analytics.load(getSegmentKey());
        break;
      default:
        break;
    }
  });
};
const beaconMware = createMiddleware(eventsMap, analyticsClientTarget);

export const analyticsClientMiddleware = store => next => action => {
  const state = store.getState();
  const featureFlags = featureFlagsSelector(state);

  if (!isWeb() || featureFlags[FEATURE_FLAGS.DISABLE_ANALYTICS_CLIENT]) {
    return next(action);
  }

  return beaconMware(store)(next)(action);
};
