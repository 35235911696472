export const getSectionHeadlineTranslation = (translationsStrings = {}) => ({
  sectionLabel: translationsStrings.default_link_label,
});

export const getPaginationTranslations = (translationsStrings = {}) => ({
  paginationNext: translationsStrings.pagination_next,
  paginationPrevious: translationsStrings.pagination_previous,
  paginationCurrent: translationsStrings.pagination_current,
  paginationTitle: translationsStrings.pagination_title,
  paginationItem: translationsStrings.pagination_item,
});

export const getProductCarouselTranslations = (translationsStrings = {}) => ({
  labels: {
    memberAccessLabel: translationsStrings.member_access_label,
    nikeByYouLabel: translationsStrings.nike_by_you_label,
    memberExclusiveText: translationsStrings.member_exclusive,
    nikeByYouText: translationsStrings.nike_by_you,
  },
});
