import striptags from 'striptags';
import { map, pipe, prop } from 'ramda';
import { extractIngredientsFromList, getPDPUrl } from '../helpers';
import {
  getCardLinkActions,
  getClickActivity,
  getCommonAnalyticsProps,
} from '../helpers/analytics';
import { analyticsProperties } from '../../../analytics/constants';
import { getButtonTitle } from '../helpers/commonHelpers';

export const prepareCardData = cardData => cardData;

export const getUrlIngredients = card => [
  ...extractIngredientsFromList(card?.products),
];

export const getAnalyticsPlacements = card =>
  map(product => {
    const styleColor = product.urlIngredients?.styleColor;

    return {
      ...getCommonAnalyticsProps(card),
      [analyticsProperties.ASSET_ID]: product.squarishId,
      [analyticsProperties.PRODUCT_ID]: product.productId,
      [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(),
      [analyticsProperties.CTA_TITLE]:
        striptags(card.titleProps.text) || striptags(getButtonTitle(card)),
      [analyticsProperties.CURRENT_CURRENCY]: product.currency,
      [analyticsProperties.CURRENT_PRICE]: product.salePrice,
      [analyticsProperties.PRICING_STATUS]: product.isOnSale
        ? 'reduced'
        : 'regular',
      [analyticsProperties.STYLE_COLOR]: styleColor,
    };
  }, card.products ?? []);

export { getCardLinkActions as getAnalyticsActions };

export const prepareTranslations = (translationsStrings = {}) => ({
  shopTheLookText: translationsStrings.shop_the_look_label,
  soldOutLabel: translationsStrings.sold_out_label,
  selectSizeLoadingLabel: translationsStrings.shop_the_look_select_size_loading,
  selectSizeLabel: translationsStrings.shop_the_look_select_size_label,
  addedToCartText:
    translationsStrings.shop_the_look_added_to_cart ?? 'Added to Bag',
  errorText:
    translationsStrings.shop_the_look_added_to_cart_error_label ??
    'Cart Update Error',
  viewBagLabel:
    translationsStrings.shop_the_look_view_bag_label ??
    'View Bag ([number-of-items])',
  checkoutLabel: translationsStrings.shop_the_look_checkout_label ?? 'Checkout',
  addItemsSingularLabel:
    translationsStrings.shop_the_look_add_items_singular_label ??
    'Add 1 Item to Bag',
  addItemsPluralLabel:
    translationsStrings.shop_the_look_add_items_plural_label ??
    'Add [number-of-items] Items to Bag',
  addToBagAddingLabel:
    translationsStrings.shop_the_look_adding_label ?? 'Adding',
  clearButtonLabel: translationsStrings.shop_the_look_clear_label ?? 'Clear',
});

export const mergeProperties = ({
  cardData,
  translations,
  urls,
  countryCode,
  languageRegion,
  languageCountryPath,
}) => {
  const { products = [], ...restCardData } = cardData;
  return {
    ...restCardData,
    ...translations,
    cartUrl: `/${languageCountryPath}cart`,
    products: products.map(product => ({
      ...product,
      country: countryCode,
      url: pipe(prop('urlIngredients'), urlIngredients =>
        getPDPUrl({
          urlIngredients,
          pdpType: prop('pdpType')(product),
          urls,
          countryCode,
          languageRegion,
          languageCountryPath,
        }),
      )(product),
    })),
  };
};
