import { assocPath, pipe } from 'ramda';
import { nanoid } from 'nanoid';
import { CARD_TYPES } from '../../../constants';

import {
  extractIngredientsFromList,
  getProductCarouselTranslations,
  getSectionHeadlineTranslation,
  getTitle,
  mergeCarouselProperties,
} from '../helpers';
import {
  getActions,
  getProductContainerPlacements,
} from '../helpers/analytics';
import { getCommonProps } from '../helpers/commonHelpers';

export const prepareCardData = cardData => {
  const conceptIds = cardData?.properties?.conceptIds;
  const viewAllButton = {
    id: nanoid(),
    actionText: 'shop all',
    actionType: 'link',
    destination: { conceptIds, type: 'GRIDWALL' },
  };
  const sectionHeadline = getTitle({
    ...cardData,
    properties: { ...cardData?.properties, actions: [viewAllButton] },
  });
  const maxResults =
    cardData?.properties?.custom?.maxResults < 8
      ? cardData?.properties?.custom?.maxResults
      : 10;

  return {
    ...getCommonProps(cardData),
    sectionHeadline,
    maxResults,
    slides: [],
    dataQa: CARD_TYPES.PRODUCT_WALL,
    collectionId: conceptIds,
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  ...getProductCarouselTranslations(translationsStrings),
  ...getSectionHeadlineTranslation(translationsStrings),
  viewAllLabel: translationsStrings.view_all,
});

export const mergeProperties = data =>
  pipe(
    assocPath(
      ['cardData', 'sectionHeadline', 'actions', 0, 'actionText'],
      data.translations.viewAllLabel,
    ),
    mergeCarouselProperties,
  )(data);

export const getUrlIngredients = card => [
  ...extractIngredientsFromList(card?.sectionHeadline.actions),
  ...extractIngredientsFromList(card?.slides),
];

export { getProductContainerPlacements as getAnalyticsPlacements };

export const getAnalyticsActions = card =>
  getActions(card.sectionHeadline.actions);

export { getBuyingToolsIngredients } from './common';
