import { createSelector } from 'reselect';
import { dataSelector } from './dataSelector';
import { pageDataPropertiesSelector } from './pageSelectors';
import createCachedSelectorOnClient from '../../utils/createCachedSelectorOnClient';
import { ownPropsSelector } from '../../utils/ownPropsSelector';
import {
  hasSlideshowWithInsetColumn,
  hasGalleriesOrDynamicCards,
} from './helpers';
import { CARD_TYPES } from '../../constants';

export const currentLayoutSelector = createSelector(
  dataSelector,
  pageDataPropertiesSelector,
  (appData, pageData) => {
    if (!pageData.layout || pageData.layout === 'generic') {
      throw new Error(
        'Legacy generic layouts and nodes based content are not supported at the moment.',
      );
    }
    return appData.layout[pageData.layout];
  },
);

export const layoutItemSelector = createCachedSelectorOnClient(
  dataSelector,
  (_, props) => props.itemId,
  (appData, itemId) => appData.layoutItems[itemId],
)((_, props) => props.itemId);

/* Selector used by the grid component to find the presence of a carousel component
  @return {boolean} The selector final value is true or false
 */
export const hasGridScrollableContentSelector = createSelector(
  dataSelector,
  ownPropsSelector,
  (appData, ownProps) => {
    const currentLayoutItems = appData?.layoutItems?.[ownProps.itemId]?.items;
    if (Array.isArray(currentLayoutItems) && currentLayoutItems.length) {
      const currentLayoutItem = currentLayoutItems[0];
      const rowItems = appData?.layoutItems?.[currentLayoutItem]?.items;
      if (Array.isArray(rowItems) && rowItems.length) {
        const rowItem = rowItems[0];
        const column = appData?.layoutItems?.[rowItem];
        const colItems = column?.items;
        if (Array.isArray(colItems) && colItems.length) {
          const colItem = colItems[0];
          const block = appData?.layoutItems?.[colItem];
          const cardType = appData?.cards?.[block.data]?.containerType;

          return (
            !hasSlideshowWithInsetColumn(column, cardType) &&
            cardType !== CARD_TYPES.SIZE_CHARTS &&
            cardType !== CARD_TYPES.BRA_SIZE_FINDER &&
            cardType !== CARD_TYPES.INTERESTS &&
            hasGalleriesOrDynamicCards(cardType)
          );
        }
      }
    }
    return false;
  },
);
