import striptags from 'striptags';
import {
  getImageOverride,
  getFocalPoint,
  prepareGenericCardData,
  extractIngredientsFromList,
  extractBuyingIngredientsFromList,
  mergeLinkWithIngredients,
  getAssetsAspectRatio,
} from '../helpers';
import { MEDIA_QUERIES, IMAGE_SIZES_MAP } from '../../../constants';
import { ORIENTATION } from '../../../enum/componentAttributes';
import { analyticsProperties } from '../../../analytics/constants';
import {
  getActions,
  getCardLinkActions,
  getCommonAnalyticsProps,
} from '../helpers/analytics';
import {
  getCommonProps,
  prepareCaptionData,
  getButtonTitle,
} from '../helpers/commonHelpers';

export const prepareCardData = (
  imageCard,
  data,
  { withDefaultImageHeight = true } = {},
) => {
  const genericCardData = prepareGenericCardData(imageCard);
  const landscapeURL = getImageOverride(
    imageCard.properties,
    MEDIA_QUERIES.desktop,
    imageCard?.properties?.landscapeURL,
  );
  const portraitURL = getImageOverride(
    imageCard.properties,
    MEDIA_QUERIES.mobile,
    imageCard?.properties?.portraitURL,
  );
  const landscapeFocalPoint = getFocalPoint(
    imageCard,
    MEDIA_QUERIES.desktop,
    ORIENTATION.landscape,
  );
  const portraitFocalPoint = getFocalPoint(
    imageCard,
    MEDIA_QUERIES.mobile,
    ORIENTATION.portrait,
  );
  const isTextBelowImage = imageCard?.properties?.custom?.layout === 'poster';
  const altText =
    imageCard?.properties?.altText ||
    (imageCard?.properties?.title ?? 'marketing image');
  const preferredOrientation = imageCard?.properties?.preferredOrientation;
  const assetsIds = {
    landscape: imageCard?.properties?.landscape?.id,
    portrait: imageCard?.properties?.portrait?.id,
    squarish: imageCard?.properties?.squarish?.id,
  };

  const landscapeAspectRatio = getAssetsAspectRatio(
    imageCard,
    MEDIA_QUERIES.desktop,
    ORIENTATION.landscape,
  );

  const portraitAspectRatio = getAssetsAspectRatio(
    imageCard,
    MEDIA_QUERIES.mobile,
    ORIENTATION.portrait,
  );

  const assetsAspectRatios = {
    portrait: portraitAspectRatio ?? landscapeAspectRatio,
    landscape: landscapeAspectRatio ?? portraitAspectRatio,
  };
  const imageHeight = imageCard?.properties?.custom?.imageHeight;

  return {
    ...genericCardData,
    ...getCommonProps(imageCard),
    altText,
    isTextBelowImage,
    landscapeURL: landscapeURL || portraitURL,
    portraitURL: portraitURL || landscapeURL,
    landscapeFocalPoint,
    portraitFocalPoint,
    preferredOrientation,
    assetsIds,
    assetsAspectRatios,
    imageHeight: withDefaultImageHeight
      ? imageHeight ?? IMAGE_SIZES_MAP.MEDIUM
      : imageHeight,
    captionProps: prepareCaptionData(imageCard),
  };
};

export { prepareTranslations } from './common';

export const mergeProperties = ({
  cardData,
  urls,
  buyingToolsList,
  countryCode,
  languageMappings = {},
}) => {
  const { actionButtons, ...restCardData } = cardData;
  const { urlParam } = languageMappings;
  return {
    ...mergeLinkWithIngredients({
      action: {
        countryCode,
        language: urlParam,
        ...restCardData,
      },
      urls,
    }),
    actionButtons: actionButtons.map(action => {
      action.countryCode = countryCode;
      action.language = urlParam;
      return mergeLinkWithIngredients({
        action,
        urls,
        property: 'destinationId',
        buyingToolsList,
      });
    }),
  };
};

export const getUrlIngredients = card => [
  ...extractIngredientsFromList([
    { urlIngredients: card?.urlIngredients },
    ...card?.actionButtons,
  ]),
];

export const getAnalyticsPlacements = card => ({
  ...getCommonAnalyticsProps(card),
  [analyticsProperties.CTA_TITLE]:
    striptags(card.titleProps.text) || striptags(getButtonTitle(card)),
  [analyticsProperties.ASSET_ID]: {
    mobile: card.preferredOrientation?.small
      ? card.assetsIds?.[card.preferredOrientation.small]
      : card.assetsIds?.portrait,
    desktop: card.preferredOrientation?.large
      ? card.assetsIds?.[card.preferredOrientation.large]
      : card.assetsIds?.landscape,
  },
  ...(card.concepts && {
    [analyticsProperties.SELECTED_CONCEPTS]: card.concepts,
  }),
  ...(card.conceptIds && {
    [analyticsProperties.SELECTED_CONCEPTS_IDS]: card.conceptIds,
  }),
});

export const getAnalyticsActions = card => [
  ...getCardLinkActions(card),
  ...getActions(card.actionButtons, 1, card.isFeatured),
];

export const getBuyingToolsIngredients = card =>
  extractBuyingIngredientsFromList(card?.actionButtons);
