import {
  APIError,
  customFetch,
  ErrorMessage,
  FetchParams,
  DEFAULT_API_URL,
} from '../../common';

type RequestHeaders = {
  callerId: string;
};

export const getUrl = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: UrlGenesisV2.PathUserNavigationurlgenesisv2PostRequest &
    RequestHeaders &
    FetchParams;
}): Promise<UrlGenesisV2.PathUserNavigationurlgenesisv2PostResponse> => {
  const {
    callerId,
    fetchParams: { timeout, baseUrl = DEFAULT_API_URL } = {},
    ...body
  } = params;
  const path = 'user_navigation/urlgenesis/v2';
  const url = `${baseUrl}/${path}`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'nike-api-caller-id': callerId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    vipAddress: 'user_navigation-urlgenesis-v2',
    path,
    timeout,
  };

  const result = await customFetch({
    fetchFn,
    url,
    options: requestOptions,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      url,
      requestOptions,
      requestBody: JSON.parse(requestOptions.body),
      originalMessage: e.message,
    });
  }
};
