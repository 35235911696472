import { CARD_ACTION_TYPES, CARD_TYPES } from '../../../constants';
import {
  extractIngredientsFromList,
  mergeLinkWithIngredients,
  prepareActionButton,
} from '../helpers';
import {
  getClickActivity,
  getCommonAnalyticsProps,
} from '../helpers/analytics';
import { analyticsProperties } from '../../../analytics/constants';

export const prepareCardData = cardData => {
  const hasSupportedItem = linkPropName => attribute =>
    !attribute?.[linkPropName]?.actionType ||
    attribute?.[linkPropName]?.actionType === CARD_ACTION_TYPES.LINK;

  return {
    containerType: CARD_TYPES.TOOLS_AND_SUPPLIES,
    cardId: cardData?.id,
    id: cardData?.id,
    tools: cardData?.tools
      .filter(hasSupportedItem('toolsLink'))
      .map((tool, index) => ({
        text: tool.toolsText || tool?.toolsLink?.actionText,
        ...(tool.toolsLink
          ? {
              analyticActionId: `${cardData.id}_tools_${index}`,
              urlIngredients: prepareActionButton(tool.toolsLink)
                ?.urlIngredients,
            }
          : {}),
      })),
    supplies: cardData?.supplies
      .filter(hasSupportedItem('supplyLink'))
      .map((supply, index) => ({
        text: supply.supplyText || supply?.supplyLink?.actionText,
        ...(supply.supplyLink
          ? {
              analyticActionId: `${cardData.id}_supplies_${index}`,
              urlIngredients: prepareActionButton(supply.supplyLink)
                ?.urlIngredients,
            }
          : {}),
      })),
  };
};
export const prepareTranslations = (translationsStrings = {}) => ({
  toolsLabel: translationsStrings.tools_label,
  suppliesLabel: translationsStrings.supplies_label,
});

export const mergeProperties = ({ cardData, translations, urls }) => ({
  ...cardData,
  toolsLabel: translations.toolsLabel,
  suppliesLabel: translations.suppliesLabel,
  tools: cardData.tools.map(tool =>
    mergeLinkWithIngredients({ urls, action: tool, property: 'url' }),
  ),
  supplies: cardData.supplies.map(supply =>
    mergeLinkWithIngredients({ urls, action: supply, property: 'url' }),
  ),
});

export const getUrlIngredients = cardData => {
  return extractIngredientsFromList([
    ...cardData?.supplies,
    ...cardData?.tools,
  ]);
};

export { getBuyingToolsIngredients } from './common';

export const getAnalyticsPlacements = card => getCommonAnalyticsProps(card);

const buildAction = ({ property, card }) =>
  card[property]
    .filter(item => item?.urlIngredients)
    .map((item, index) => ({
      id: item.analyticActionId,
      [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
        item.urlIngredients.type,
        index,
        item.text,
      ),
    }));

export const getAnalyticsActions = card => [
  ...buildAction({ card, property: 'supplies' }),
  ...buildAction({ card, property: 'tools' }),
];
