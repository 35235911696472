import { addIndex, chain, pipe } from 'ramda';
import striptags from 'striptags';
import { getGalleryActions, getGalleryPlacements } from '../helpers/analytics';
import { replaceNewLineSymbolWithBrForPlainText } from '../helpers/textHelpers';
import { getSectionHeadlineTranslation } from '../helpers';
import { CARD_TYPES } from '../../../constants';
import { prepareTranslations as videoTranslations } from './video';
import {
  getCommonProps,
  getContainerType,
  getTitle,
} from '../helpers/commonHelpers';
import cardMethods from './index';

const chainWithIndex = addIndex(chain);

/**
 * Returns the SEO attributes used to generate JSON LD stucture.
 * @param {Object} stepSlide - the result of prepareCardData
 * @param {Object} imageSlide - optional param with image data
 * @returns {Object} SEO props
 */
const getSEOData = (stepSlide, imageSlide) => {
  const { text = '' } = stepSlide?.bodyProps || {};
  const imageSource = imageSlide || stepSlide;

  return {
    text: pipe(replaceNewLineSymbolWithBrForPlainText, striptags)(text),
    imageURL: imageSource?.landscapeURL || imageSource?.portraitURL,
  };
};

const getCarouselSEOData = subslides => {
  const textSlide = subslides.find(
    card => card.containerType === CARD_TYPES.TEXT,
  );
  const imageSlide = subslides.find(
    card => card.containerType === CARD_TYPES.IMAGE,
  );
  const textSource = textSlide || imageSlide || subslides[0];

  return getSEOData(textSource, imageSlide);
};

export const prepareCardData = (cardData, data) => {
  const slides = chainWithIndex((slideId, index) => {
    const slide = data.nodes[slideId];
    const headline = {
      isListicleHeadline: true,
      counter: index + 1,
      title: getTitle(slide).title,
    };
    if (slide.subType === CARD_TYPES.CAROUSEL) {
      const subslides = slide.nodes.map(id => {
        const subSlide = data.nodes[id];
        return cardMethods[getContainerType(subSlide)].prepareCardData(
          subSlide,
          data,
        );
      });
      return [
        {
          ...headline,
          seo: {
            ...getCarouselSEOData(subslides),
            title: headline.title,
          },
        },
        ...subslides,
      ];
    }
    const preparedCard = cardMethods[getContainerType(slide)].prepareCardData(
      slide,
      data,
    );
    return [
      {
        ...headline,
        seo: {
          ...getSEOData(preparedCard),
          title: headline.title,
        },
      },
      {
        ...preparedCard,
        // removing title props to avoid duplication with listicle headline
        titleProps: {},
      },
    ];
  }, cardData?.nodes ?? []);
  return {
    ...getCommonProps(cardData),
    sectionHeadline: getTitle(cardData),
    slides,
  };
};

export const prepareTranslations = translationsStrings => ({
  ...videoTranslations(translationsStrings),
  ...getSectionHeadlineTranslation(translationsStrings),
});

export {
  getGalleryUrlIngredients as getUrlIngredients,
  mergeGalleryProperties as mergeProperties,
} from '../helpers/carouselHelper/carouselHelper';

const excludeHeadlines = card => ({
  ...card,
  slides: card.slides.filter(slide => slide.containerType),
});

export const getAnalyticsPlacements = pipe(
  excludeHeadlines,
  getGalleryPlacements,
);

export const getAnalyticsActions = pipe(excludeHeadlines, getGalleryActions);

export { getBuyingToolsIngredients } from './common';
