import { addIndex, map } from 'ramda';
import striptags from 'striptags';
import {
  getTitle,
  getTeamSelectorNodes,
  mergeLinkWithIngredients,
  extractIngredientsFromList,
  getSectionHeadlineTranslation,
} from '../helpers';
import {
  getActions,
  getClickActivity,
  getCommonAnalyticsProps,
  getSectionHeadlinePlacements,
} from '../helpers/analytics';
import { analyticsProperties } from '../../../analytics/constants';
import { getCommonProps } from '../helpers/commonHelpers';

export const prepareCardData = (teamSelectorCard, { nodes }) => ({
  ...getCommonProps(teamSelectorCard),
  items: getTeamSelectorNodes({ nodes, id: teamSelectorCard?.id }),
  sectionHeadline: getTitle(teamSelectorCard),
});

export const prepareTranslations = (translationsStrings = {}) => ({
  ...getSectionHeadlineTranslation(translationsStrings),
  labels: {
    seeAllLabel: translationsStrings.team_selector_see_all,
    seeLessLabel: translationsStrings.team_selector_see_less,
  },
});

export const mergeProperties = ({
  cardData,
  urls = [],
  translations = {},
}) => ({
  ...cardData,
  ...translations,
  items: cardData.items.map(item =>
    mergeLinkWithIngredients({ action: item, urls, property: 'url' }),
  ),
});

export const getUrlIngredients = card =>
  extractIngredientsFromList(card?.items);

export const getAnalyticsPlacements = card => [
  ...getSectionHeadlinePlacements(card),
  getCommonAnalyticsProps(card),
];

const mapWithIndex = addIndex(map);

export const getAnalyticsActions = card => [
  ...getActions(card.sectionHeadline.actions),
  ...mapWithIndex(
    (item, index) => ({
      id: item.id,
      [analyticsProperties.CTA_TITLE]: striptags(item.label),
      [analyticsProperties.CLICK_ACTIVITY]: getClickActivity(
        item.destinationType,
        index + 1,
        item.label,
      ),
      [analyticsProperties.ASSET_ID]: item.assetId,
    }),
    card.items,
  ),
];

export { getBuyingToolsIngredients } from './common';
