import { customFetch, FetchParams, DEFAULT_API_URL } from '../../common';

export const getSkuData = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: Inventory.SkuDataFetchOptions & FetchParams;
}): Promise<Inventory.SkuDataObject> => {
  const {
    gtin,
    country,
    merchGroup,
    fetchParams: { timeout, baseUrl = DEFAULT_API_URL } = {},
  } = params;
  const path = 'merch/skus/v2';
  const url = `${baseUrl}/${path}/?filter=gtin(${gtin})&filter=country(${country})`;

  const skuDataResponse = await customFetch({
    fetchFn,
    url,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout,
      path,
    },
  });
  const skuData = await skuDataResponse.json();
  return skuData?.objects?.find(
    (skuDataObject: Inventory.SkuDataObject) =>
      skuDataObject.merchGroup === merchGroup,
  );
};
