import {
  DEFAULT_API_URL,
  customFetch,
  ExtendedFetchOptions,
  transformParamsToString,
  FetchParams,
  ErrorMessage,
  APIError,
} from '../../common';

export const getBannerPreview = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: ContentApi.BannerPreviewFetchOptions & FetchParams;
}): Promise<ContentApi.BannerResponse> => {
  const {
    language,
    marketplace,
    channelId,
    bannerId,
    resource,
    callerId,
    token,
    fetchParams: { timeout = 1000, baseUrl = DEFAULT_API_URL } = {},
  } = params;
  const paramsString = transformParamsToString({
    language,
    marketplace,
    channelId,
  });

  const options: ExtendedFetchOptions['options'] = {
    method: 'GET',
    timeout,
    headers: {
      'nike-api-caller-id': callerId,
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${baseUrl}/content/previewproxy/v1/banner/${bannerId}${paramsString}`;

  const result = await customFetch({
    fetchFn,
    url,
    resource,
    options,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      url,
      requestOptions: options,
      originalMessage: e.messsage,
    });
  }
};
