import { API_BASE_URL } from '@nike/ciclp-config';
import logger from '@nike/ciclp-utils/logger';
import { getAvailability } from '@nike/ciclp-data-fetch-layer';
import { put, call, select, takeEvery, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { getCardDataSelector } from '../../store/data/getCardDataSelector';
import {
  analyticsActions,
  shopTheLookSizesLoadAction,
  shopTheLookAddToCart,
  shopTheLookProductsActions,
} from '../../actions';
import {
  composeSizesByProductIds,
  getStyleColorsAndMerchGroups,
  mergeSizes,
} from './helpers';
import { addToCart } from '../../services/nikeShop';
import { eventNames, clickActivityProperties } from '../../analytics/constants';
import { countryCodeSelector } from '../../store/state/stateSelector';
import { ALL_SETTLED_STATUSES, allSettled } from '../../utils/allSettled';

export const addProductsToCartSaga = function* (products) {
  const cartResp = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const product of products) {
    const { cartResponse, timeout } = yield race({
      cartResponse: call(addToCart, product),
      timeout: delay(10000),
    });
    if (timeout) {
      logger.warn({
        message: '[STL]: Timeout error while adding products to cart',
        details: {
          product,
        },
      });
      return [];
    }
    cartResp.push({ cartResponse, product });
    yield delay(500);
  }
  return cartResp;
};

export const shopTheLookCartSaga = function* ({ payload }) {
  try {
    const { selectedProducts, cardId } = payload;
    const addToCartResponse = yield call(
      addProductsToCartSaga,
      selectedProducts,
    );
    if (addToCartResponse.length) {
      yield put(
        shopTheLookAddToCart.success({
          addToCartResponse,
          cardId,
        }),
      );
      yield put(shopTheLookProductsActions.resetSelectedSkus());
    } else {
      const productsList = selectedProducts.map(({ skuId, productId }) => ({
        skuId,
        productId,
      }));

      logger.warn({
        name: 'Error',
        message: '[STL]: Error while adding products to cart',
        details: {
          products: productsList,
        },
      });
    }
  } catch (error) {
    const { name, message, details } = error;
    const errorPayload = {
      name,
      message,
      details,
    };

    logger.warn(errorPayload);
    yield put(shopTheLookAddToCart.error(errorPayload));
  }
};

export const shopTheLookSizesSaga = function* ({ payload }) {
  try {
    const country = yield select(countryCodeSelector);
    const styleColorsAndMerchGroups = getStyleColorsAndMerchGroups(payload);
    const getAvailabilityRequests = styleColorsAndMerchGroups.map(
      ({ styleColor, merchGroup }) =>
        call(getAvailability, {
          fetchFn: globalThis.extendedFetch,
          params: {
            country: country?.toUpperCase(),
            styleColor,
            merchGroup,
            fetchParams: {
              timeout: 10000,
              baseUrl: API_BASE_URL,
            },
          },
        }),
    );
    const allSizesData = yield allSettled(
      getAvailabilityRequests,
      '[STL]: Error while requesting Shop The Look Product availability',
    );
    const allSizes = mergeSizes(
      allSizesData
        .filter(({ status }) => status === ALL_SETTLED_STATUSES.FULFILLED)
        .map(({ value }) => value),
    );
    const sizesByProductId = composeSizesByProductIds(
      allSizes,
      payload?.productStyleColors,
    );
    yield put(shopTheLookSizesLoadAction.success({ sizesByProductId }));
  } catch (error) {
    const { name = 500, message, stack = {}, details } = error;

    logger.warn({
      name,
      message: `[STL]: Error while requesting Shop The Look SKUs availability: ${message}`,
      stack,
      details,
    });
    yield put(shopTheLookSizesLoadAction.error({ error }));
  }
};

export const shopTheLookCartAnalyticsSaga = function* ({ payload }) {
  try {
    const { cardId, addToCartResponse: addToCartItems } = payload;
    const cardData = yield select(getCardDataSelector, cardId);

    // eslint-disable-next-line no-restricted-syntax
    for (const addToCartItem of addToCartItems) {
      const { cartResponse, product } = addToCartItem;
      const { type } = cartResponse;
      const { productId, skuId } = product;
      if (type === '@carts/ADD_TO_CARTS_SUCCESS') {
        const productData = cardData?.products.find(
          item => item.productId === productId,
        );
        yield put(
          analyticsActions.track({
            eventName: eventNames.PRODUCT_ADDED,
            clickActivity: clickActivityProperties[eventNames.PRODUCT_ADDED],
            productData: {
              skuId,
              ...productData,
            },
          }),
        );
      }
    }
  } catch (error) {
    const { name = 500, stack = {}, details, message } = error;

    logger.warn({
      name,
      message: `[STL]: Error while sending Shop The Look Add to Cart analytics. ${message}`,
      stack,
      details,
    });
  }
};

export const watchShopTheLookSizesSaga = function* () {
  yield takeEvery(
    shopTheLookSizesLoadAction.request.type,
    shopTheLookSizesSaga,
  );
};

export const watchShopTheLookCartSaga = function* () {
  yield takeEvery(
    shopTheLookAddToCart.success.type,
    shopTheLookCartAnalyticsSaga,
  );
};
