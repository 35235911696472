import { CARD_TYPES } from '../../../constants';
import { formatPrice } from '../helpers';

export const prepareCardData = (cardData = {}) => {
  const { id, estimatedCost, estimatedCostCurrency, timeRequired } = cardData;

  return {
    cardId: id,
    containerType: CARD_TYPES.META_BOX,
    estimatedCost,
    estimatedCostCurrency,
    timeRequired,
  };
};

export const prepareTranslations = (translationsStrings = {}) => ({
  timeLabel: translationsStrings.meta_box_time_label,
  costLabel: translationsStrings.meta_box_cost_label,
  durationLabel: translationsStrings.duration_in_min,
});

export const mergeProperties = ({
  cardData,
  translations,
  languageMappings,
}) => {
  const timeRequired = {
    title: translations?.timeLabel,
    value: `${cardData.timeRequired} ${translations.durationLabel}`,
  };

  let estimatedCost = null;
  try {
    const formattedEstimatedCost = formatPrice(
      cardData.estimatedCost,
      languageMappings?.hreflang,
      cardData.estimatedCostCurrency,
    );
    estimatedCost = {
      title: translations?.costLabel,
      value: formattedEstimatedCost,
    };
  } catch (error) {
    /**
     * Price formatting can result in an error when incorrect currency is
     * passed to the used @nike/ux-tread-currency. For the product slides
     * this is logged using redux actions which are not available in selectors
     * here. For article meta we could ignore errors and just skip estimated
     * cost rendering.
     */
  }

  return {
    cardId: cardData.cardId,
    containerType: CARD_TYPES.META_BOX,
    timeRequired,
    estimatedCost,
  };
};

export {
  getUrlIngredients,
  getAnalyticsPlacements,
  getAnalyticsActions,
  getBuyingToolsIngredients,
} from './common';
