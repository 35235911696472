import {
  APIError,
  DEFAULT_API_URL,
  ErrorMessage,
  FetchParams,
  customFetch,
} from '../../common';

export const subscribeToNotifications = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: NotifyMe.Notification & FetchParams;
}): Promise<NotifyMe.PathNotifymeNotificationsv1PostResponse> => {
  const { fetchParams: { baseUrl = DEFAULT_API_URL, timeout } = {}, ...body } =
    params;
  const path = 'targeted_marketing/notifyme_notifications/v1';
  const url = `${baseUrl}/${path}`;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
    timeout,
    path,
  };

  const result = await customFetch({
    fetchFn,
    url,
    options: requestOptions,
  });
  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      url,
      requestOptions,
      requestBody: JSON.parse(requestOptions.body),
      originalMessage: e.message,
    });
  }
};
