import { nanoid } from 'nanoid';
import {
  pipe,
  path,
  values,
  filter,
  propEq,
  map,
  find,
  reduce,
  reduced,
  curry,
  pathEq,
  head,
  mergeDeepRight,
  assocPath,
  concat,
  lensPath,
  set,
} from 'ramda';
import { CARD_TYPES } from '../../constants';

export const ENTITIES = {
  LAYOUT: 'layout',
  GRID: 'grid',
  ROW: 'row',
  COL: 'col',
  BLOCK: 'block',
  CARD: 'card',
  NODE: 'node',
};

const getLayoutEntityMethods = (type, mode) => ({
  type,
  getChildren: (item, data) =>
    pipe(
      map(childId => data.entities.layoutItems[childId]),
      filter(Boolean),
    )(item.items ?? []),
  isOneOfType: propEq('mode', mode),
  prop: mode === 'layout' ? 'layout' : 'layoutItems',
});

export const nodeIdLinkReplacer = nodeId => nodeId?.replace?.('LINK::', '');

export const entitiesMethods = {
  [ENTITIES.LAYOUT]: getLayoutEntityMethods(ENTITIES.LAYOUT, 'layout'),
  [ENTITIES.GRID]: getLayoutEntityMethods(ENTITIES.GRID, 'grid'),
  [ENTITIES.ROW]: getLayoutEntityMethods(ENTITIES.ROW, 'row'),
  [ENTITIES.COL]: getLayoutEntityMethods(ENTITIES.COL, 'col'),
  [ENTITIES.BLOCK]: {
    ...getLayoutEntityMethods(ENTITIES.COL, 'block'),
    getChildren: (item, data) =>
      item.data && data.entities.cards[item.data]
        ? [data.entities.cards[item.data]]
        : [],
  },
  [ENTITIES.CARD]: {
    type: ENTITIES.CARD,
    getChildren: (item, data) =>
      concat(
        pipe(
          map(nodeId => data.entities.nodes[nodeIdLinkReplacer(nodeId)]),
          filter(Boolean),
        )(item.nodes ?? []),
        map(variant => data.entities.cards[variant])(item.variants ?? []),
      ),
    isOneOfType: (item, data) => !!data.entities.cards[item.id],
    prop: 'cards',
  },
  [ENTITIES.NODE]: {
    type: ENTITIES.NODE,
    getChildren: (item, data) =>
      map(nodeId => data.entities.nodes[nodeId], item?.nodes ?? []),
    isOneOfType: (item, data) =>
      !data.entities.cards[item.id] && !!data.entities.nodes[item.id],
    prop: 'nodes',
  },
};

const getTypeOfNode = (item, data) =>
  pipe(
    values,
    find(entity => entitiesMethods[entity].isOneOfType(item, data)),
  )(ENTITIES);

export const getLayout = pipe(path(['entities', 'layout']), values, head);

export const findTreeNode = curry((entityType, predicate, parent, data) => {
  parent = parent || getLayout(data);
  const parentType = getTypeOfNode(parent, data);
  const children = entitiesMethods[parentType].getChildren(parent, data);
  return reduce(
    (result, item) => {
      const itemType = getTypeOfNode(item, data);
      if (itemType === entityType && predicate(item)) {
        return reduced(item);
      }
      const foundNode = findTreeNode(entityType, predicate, item, data);
      return foundNode ? reduced(foundNode) : undefined;
    },
    undefined,
    children,
  );
});

export const findCard = findTreeNode(ENTITIES.CARD);
export const findLocalNav = findCard(
  pathEq(['properties', 'containerType'], CARD_TYPES.LOCAL_NAV),
);
export const findMerchMenu = findCard(
  pathEq(['properties', 'containerType'], CARD_TYPES.MERCH_MENU),
);
export const findTitle = findCard(propEq('subType', CARD_TYPES.TITLE));
export const findDynamicRelatedContent = findCard(
  pathEq(['properties', 'containerType'], CARD_TYPES.DYNAMIC_RELATED_FILMSTRIP),
);
export const findRelatedContent = findCard(
  pathEq(['properties', 'containerType'], CARD_TYPES.RELATED_FILMSTRIP),
);
export const findArticleFooter = findCard(
  propEq('subType', CARD_TYPES.ARTICLE_FOOTER),
);

export const findMetaBox = findCard(propEq('subType', CARD_TYPES.META_BOX));

export const filterTreeNodes = curry((entityType, predicate, parent, data) => {
  parent = parent || getLayout(data);
  const parentType = getTypeOfNode(parent, data);
  const children = entitiesMethods[parentType].getChildren(parent, data);
  return reduce(
    (result, item) => {
      const itemType = getTypeOfNode(item, data);
      return filter(Boolean, [
        ...result,
        itemType === entityType && predicate(item) ? item : undefined,
        ...filterTreeNodes(entityType, predicate, item, data),
      ]);
    },
    [],
    children,
  );
});

export const updateTreeNode = (item, changes, data) => {
  if (!item?.id) {
    return data;
  }
  const type = getTypeOfNode(item, data);
  return assocPath(
    ['entities', entitiesMethods[type].prop, item.id],
    mergeDeepRight(item, changes),
    data,
  );
};

const ZERO_OFFSET = { small: 0, medium: 0, large: 0 };
const FULL_COL_WIDTH = 12;
const getColSpan = offset => FULL_COL_WIDTH - offset;

// creates 12-column grid with one card
export const createGridWithCard = (
  card,
  { fluid = false, offset = ZERO_OFFSET, span, gridIdPrefix = '' } = {},
) => {
  const gridId = `${gridIdPrefix}${nanoid()}`;
  const rowId = `${nanoid()}-row`;
  const colId = `${nanoid()}-col`;
  const blockId = `${nanoid()}-block`;
  return {
    gridId,
    data: {
      entities: {
        layoutItems: {
          [gridId]: {
            id: gridId,
            mode: 'grid',
            items: [rowId],
            attributes: { fluid },
            display: { small: true, medium: true, large: true },
          },
          [rowId]: {
            id: rowId,
            mode: 'row',
            items: [colId],
            attributes: { gutter: true },
          },
          [colId]: {
            id: colId,
            mode: 'col',
            items: [blockId],
            span: span || {
              small: getColSpan(offset.small),
              medium: getColSpan(offset.medium),
              large: getColSpan(offset.large),
            },
            offset,
          },
          [blockId]: { id: blockId, mode: 'block', data: card.id },
        },
        cards: {
          [card.id]: card,
        },
      },
    },
  };
};

const removeTreeNodes = (entity, data) => {
  if (!entity) {
    return data;
  }

  const entityType = getTypeOfNode(entity, data);
  const entityMethods = entitiesMethods[entityType];
  const { prop: dataProp } = entityMethods;
  // This hack is needed to remove entity with a particular id but keep the rest object params
  const { [entity.id]: entityToRemove, ...updatedEntities } =
    data.entities[dataProp];
  const children = entitiesMethods[entityType].getChildren(entity, data);
  const updatedData = set(
    lensPath(['entities', dataProp]),
    { ...updatedEntities },
    data,
  );

  return reduce(
    (acc, child) => removeTreeNodes(child, acc),
    updatedData,
    children,
  );
};

const removeLayoutGridItem = (gridId, data) => {
  if (!gridId) {
    return data;
  }

  const layout = getLayout(data);
  const updatedLayout = {
    ...layout,
    items: layout.items.filter(id => id !== gridId),
  };

  return set(lensPath(['entities', 'layout', layout.id]), updatedLayout, data);
};

export const removeGridWithSpecificCardFromTree = (card, data) => {
  const grids = filterTreeNodes(
    ENTITIES.GRID,
    grid =>
      findTreeNode(ENTITIES.CARD, item => item.id === card.id, grid, data),
    null,
    data,
  );

  return reduce(
    (acc, grid) => removeLayoutGridItem(grid.id, removeTreeNodes(grid, acc)),
    data,
    grids,
  );
};
