import { IMAGE_SIZES_MAP } from '../../../constants';

export const hasValidAspectRatio = aspectRatios => {
  if (!aspectRatios) {
    return false;
  }

  const ratioValues = Object.values(aspectRatios);

  return (
    ratioValues.length > 1 && ratioValues.every(aspectRatio => aspectRatio > 0)
  );
};

export const resolveVideoHeight = (
  aspectRatios,
  imageHeight = IMAGE_SIZES_MAP.MAINTAIN,
) => {
  const isMaintainHeight = imageHeight === IMAGE_SIZES_MAP.MAINTAIN;
  const assetsAspectRatios = isMaintainHeight ? aspectRatios : undefined;

  if (isMaintainHeight && !hasValidAspectRatio(assetsAspectRatios)) {
    return {
      imageHeight: IMAGE_SIZES_MAP.MEDIUM,
      assetsAspectRatios: undefined,
    };
  }

  return {
    imageHeight,
    assetsAspectRatios,
  };
};
