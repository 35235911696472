import {
  DEFAULT_API_URL,
  APIError,
  FetchParams,
  ErrorMessage,
  customFetch,
} from '../../common';
import { SearchPagesResponse } from './types';

export const getPageById = async ({
  fetchFn,
  params,
}: {
  fetchFn: typeof fetch;
  params: { pageId: string } & FetchParams;
}): Promise<SearchPagesResponse> => {
  const { pageId, fetchParams: { baseUrl = DEFAULT_API_URL, timeout } = {} } =
    params;
  const path = `content/search_pages/v1/${pageId}`;
  const url = `${baseUrl}/${path}`;
  const requestOptions = {
    method: 'GET',
    timeout,
    path,
  };

  const result = await customFetch({
    fetchFn,
    url,
    options: requestOptions,
  });

  try {
    return await result.json();
  } catch (e) {
    throw new APIError(ErrorMessage.JSON_PARSE, {
      url,
      requestOptions,
      originalMessage: e.message,
    });
  }
};
